import { Drawer, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import clsx from 'clsx';
import styles from './EditEmailTemplates.module.css';
import { CustomTypography } from './custom/CustomTypography';
import { EmailTemplate, IEmailTemplate } from './EmailTemplate';
import { EditEmailTemplate } from './EditEmailTemplate';

type TEditEmailTemplates = {
  isOpen: boolean;
  close: () => void;
  templates: IEmailTemplate[];
};

export const EditEmailTemplates: FC<TEditEmailTemplates> = ({ isOpen, close, templates }) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<IEmailTemplate | undefined>(
    undefined,
  );
  const [isEditTemplateOpen, setIsEditTemplateOpen] = React.useState(false);

  return (
    <>
      <Drawer
        onClose={() => close()}
        open={isOpen}
        anchor="right"
        variant="temporary"
        classes={{ paper: styles['drawer-paper'] }}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Настроить шаблоны писем
            </CustomTypography>
            <IconButton onClick={close} className={styles['close-button']}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <CustomTypography className={clsx('text-15', styles['input-description'])} color="grey">
              Настройте шаблоны писем для регистрации, восстановления пароля и других событий
            </CustomTypography>
          </div>
          {templates.map((template) => {
            return (
              <EmailTemplate
                key={template.action}
                template={template}
                onClick={() => {
                  setSelectedTemplate(template);
                  setIsEditTemplateOpen(true);
                }}
              />
            );
          })}
        </div>
      </Drawer>
      <EditEmailTemplate
        isOpen={isEditTemplateOpen}
        close={() => {
          setIsEditTemplateOpen(false);
          setSelectedTemplate(undefined);
        }}
        template={selectedTemplate}
      />
    </>
  );
};
