import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TLdapParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import TextField from '@mui/material/TextField';
import { useLazyGetSMSCredentialsQuery } from '../redux/services/sms';
import { SMS_PROVIDER_URL } from '../constants';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalWithAction } from './modal/ModalWithAction';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';

export type EditSmsProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
  admin_login: string;
  admin_password: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  admin_login: yup
    .string()
    .required('Обязательное поле')
    .max(33, 'Логин не может превышать 33 символа'),
  admin_password: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Пароль не может превышать 50 символов'),
});

export const EditSmsProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditSmsProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<string | null>(null);
  const [updateProvider, updateResult] = useUpdateProviderMutation();
  const [getSMSСredentials] = useLazyGetSMSCredentialsQuery();
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateResult.isSuccess) close();
  }, [updateResult]);

  useEffect(() => {
    const setAdminСredentials = async () => {
      const { data: credentials } = await getSMSСredentials({
        client_id: clientId,
        provider_id: providerToEdit?.id || '',
      });
      setValue('admin_login', credentials?.admin_login || '');
      setValue('admin_password', credentials?.admin_password || '');
    };

    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
      setAdminСredentials();
    }
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (provider?: Partial<TMiscProvider & { provider_id: string }>) => {
    try {
      let selectedCopy:
        | Partial<
            Omit<TMiscProvider, 'avatar'> & {
              avatar?: File | null;
              provider_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { type, avatar, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;

      if (type !== MiscProviderType.SMS) {
        setClipboardModalOpen(true);
      } else {
        if (avatar && typeof avatar !== 'string') {
          setOverrideImage(URL.createObjectURL(avatar));
          setValue('avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<
                EditSmsProviderInputs & { params: string },
                'avatar' | 'admin_login' | 'admin_password'
              >
            >
          ).forEach((field) => {
            if (
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            if (field === 'params') {
              if (restInputs.params) {
                const { admin_login, admin_password } = restInputs.params as TLdapParams;
                setValue('admin_login', admin_login || '', { shouldDirty: !provider });
                setValue('admin_password', admin_password || '', { shouldDirty: !provider });
              }
              return;
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditSmsProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'admin_login' | 'admin_password'> & {
              avatar?: File | null;
              provider_id: string;
              admin_login: string;
              admin_password: string;
            }
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'password_required' ||
            field === 'is_public'
          ) {
            acc[field] = data[field];
            return acc;
          }
          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType.SMS,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать способ входа по смс
            </CustomTypography>
            <IconWithTooltip
              iconType="pasteSettings"
              action={() => {
                setFields();
              }}
            />
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Адрес смс сервиса
            </CustomTypography>
            <TextField
              className="custom"
              style={{ marginBottom: 32 }}
              value={SMS_PROVIDER_URL}
              disabled={true}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Логин
            </CustomTypography>
            <TextField
              {...register('admin_login', {
                required: true,
                onChange: () => {
                  if (errors.admin_login) clearErrors('admin_login');
                },
              })}
              className="custom"
              error={!!errors.admin_login}
              helperText={errors.admin_login ? errors.admin_login.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Логин от личного кабинета сервисного центра
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Пароль
            </CustomTypography>
            <PasswordTextfield
              {...register('admin_password', {
                required: true,
                onChange: () => {
                  if (errors.admin_password) clearErrors('admin_password');
                },
              })}
              className="custom"
              error={!!errors.admin_password}
              helperText={errors.admin_password ? errors.admin_password.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Пароль от личного кабинета сервисного центра
            </CustomTypography>
            <ProviderFooter type={MiscProviderType.SMS} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для смс провайдера."
      />
    </Drawer>
  );
};
