import React from 'react';
import { CustomTypography } from './custom/CustomTypography';
import styles from './Footer.module.css';
import { TRUSTED_VERSION, COPYRIGHT, MANUAL_URL } from '../constants';

export const Footer = () => {
  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footer']}>
        <CustomTypography color="grey" className="text-12">
          {TRUSTED_VERSION}
        </CustomTypography>
        <CustomTypography color="grey" className="text-12">
          |
        </CustomTypography>
        <CustomTypography color="grey" className="text-12">
          {COPYRIGHT}
        </CustomTypography>
        <CustomTypography color="grey" className="text-12">
          |
        </CustomTypography>
        <CustomTypography color="grey" className="text-12">
          <a href={MANUAL_URL} target="_blank" rel="noopener noreferrer" className={styles.link}>
            Справка
          </a>
        </CustomTypography>
      </div>
    </div>
  );
};
