import React, { FC, useState } from 'react';
import styles from './ChangePassword.module.css';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { checkCredentials } from '../../requests/user';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { useChangePasswordMutation } from '../../redux/services/user';
import { validatePassword } from '../../helpers';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useGetSettingsQuery } from '../../redux/services/settings';
import { CustomTypography } from '../custom/CustomTypography';

const mapStateToProps = (state: RootState) => ({
  login: state.user.userProfile.login,
  userId: state.user.userProfile.id,
  passwordChangeRequired: state.user.userProfile.password_change_required,
});

type TChangePassword = {
  login?: string;
  userId?: string;
  passwordChangeRequired?: boolean;
};

export const ChangePasswordComponent: FC<TChangePassword> = ({
  login = '',
  userId,
  passwordChangeRequired,
}) => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  // #874 const [repeatPassword, setRepeatPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<null | string>(null);
  const [currentPasswordError, setCurrentPasswordError] = useState<null | string>(null);
  // #874 const [repeatPasswordError, setRepeatPasswordError] = useState<null | string>(null);
  const [changePassword] = useChangePasswordMutation();
  const { data: dataSettings } = useGetSettingsQuery();

  const getCurrentPasswordError = () => {
    if (!currentPassword) return setCurrentPasswordError('Укажите текущий пароль');
    if (newPassword === currentPassword) {
      return setNewPasswordError('Текущий и новый пароль не должны совпадать');
    }
  };

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите новый пароль');
    const validationError = validatePassword(newPassword, dataSettings);
    if (validationError) {
      return setNewPasswordError(validationError);
    }
    if (newPassword === currentPassword) {
      return setNewPasswordError('Текущий и новый пароль не должны совпадать');
    }
  };

  const handleChangePassword = async () => {
    if (!currentPasswordError && !newPasswordError && newPassword && userId) {
      const verified = currentPassword && (await checkCredentials(login, currentPassword));

      if (verified || passwordChangeRequired) {
        await changePassword({ newPassword, userId, revokeTokens: true });
        window.location.reload();
      } else if (verified === false) {
        setCurrentPasswordError('Неправильный текущий пароль');
      }
    }
  };
  return (
    <div className={'wrapper'}>
      <div className={'content'}>
        <div className={styles['change-password']}>
          <CustomTypography
            style={{ marginBottom: 24 }}
            className={clsx('text-24-medium', 'font-golos')}
          >
            Изменить пароль
          </CustomTypography>
          <CustomTypography
            color="secondary"
            style={{ marginBottom: 24 }}
            className={clsx('text-14')}
          >
            После смены пароля произойдет выход из аккаунта на всех устройствах, где вы вошли с
            текущим паролем.
          </CustomTypography>
          {userId && (
            <>
              {!passwordChangeRequired && (
                <>
                  <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
                    Текущий пароль
                  </CustomTypography>
                  <PasswordTextfield
                    value={currentPassword}
                    onChange={(e) => {
                      if (currentPasswordError) setCurrentPasswordError(null);
                      setCurrentPassword(e.target.value);
                    }}
                    onBlur={getCurrentPasswordError}
                    className={clsx('custom', styles['password-textfield'])}
                    fullWidth
                    variant="standard"
                    FormHelperTextProps={{
                      className: clsx('text-14'),
                    }}
                    error={!!currentPasswordError}
                    helperText={currentPasswordError ? currentPasswordError : ''}
                  />
                </>
              )}
              <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
                Новый пароль
              </CustomTypography>
              <PasswordTextfield
                value={newPassword}
                onChange={(e) => {
                  if (newPasswordError) setNewPasswordError(null);
                  setNewPassword(e.target.value);
                }}
                onBlur={getNewPasswordError}
                className={clsx('custom', styles['password-textfield'])}
                FormHelperTextProps={{
                  className: clsx('text-14'),
                }}
                error={!!newPasswordError}
                helperText={newPasswordError ? newPasswordError : ''}
                fullWidth
                variant="standard"
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
                <Button onClick={() => navigate(-1)} variant="custom" color="secondary">
                  Отмена
                </Button>
                <Button onClick={handleChangePassword} variant="custom" style={{ marginLeft: 24 }}>
                  Изменить
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const ChangePassword = connect(mapStateToProps)(ChangePasswordComponent);
