import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  AccountTypes,
  useBindKloudAccountMutation,
  useGetExternalAccountsQuery,
} from '../../redux/services/user';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import {
  EGetProviderAction,
  TKloudParams,
  TMiscProvider,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import { CLIENT_ID } from '../../constants';
import { callKloud, checkKloudAvialability } from '../../requests/kloud';
import { NumericFormatCustom } from '../NumericFormatCustom';
import { CustomTypography } from '../custom/CustomTypography';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

type BindKloudAccountComponent = {
  userId?: string;
};

type TKloudProvider = Omit<TMiscProvider, 'params'> & { params: TKloudParams };

export const BindKloudAccountComponent: FC<BindKloudAccountComponent> = ({ userId }) => {
  const navigate = useNavigate();
  const { provider_id = '' } = useParams<{ provider_id: string }>();

  const { data: providers } = useGetProvidersQuery({
    client_id: CLIENT_ID,
    onlyActive: true,
    action: EGetProviderAction.auth,
  });
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userId), {
    skip: !userId,
  });
  const [bindKloudAccount] = useBindKloudAccountMutation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isCallKloud, setIsCallKloud] = useState(false);
  const [externalAccountExists, setExternalAccountExists] = useState(false);
  const [kloudProvider, setKloudProvider] = useState<TKloudProvider>();
  const [[minute, second], setTime] = useState([1, 30]);
  const phoneNumberWithCode = '+7' + phoneNumber;

  useEffect(() => {
    const kloudProvider = providers?.find((provider) => String(provider.id) === provider_id);
    setKloudProvider(kloudProvider as TKloudProvider);
  }, [provider_id, providers]);

  useEffect(() => {
    setTime([1, 30]);
  }, [isCallKloud]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (
      externalAccounts?.find(
        (account) => account.sub === phoneNumberWithCode && account.type === AccountTypes.KLOUD,
      )
    )
      return 'Номер телефона уже привязан к вашему аккаунту';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const getCodeError = () => {
    if (!code) return 'Укажите 4 цифры';
  };

  const codeInput = () => {
    setIsCallKloud((isCallKloud) => !isCallKloud);
    setCodeError('');
    setCode('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
    if (externalAccountExists) setExternalAccountExists(false);
  };

  const handleCallKloud = async () => {
    if (getPhoneNumberError()) return;
    try {
      if (!externalAccountExists) {
        const response = await checkKloudAvialability(phoneNumberWithCode, provider_id);

        if (!response?.isAvailable) {
          setExternalAccountExists(true);
          return setPhoneNumberError(
            `Телефон уже используется ${
              response?.nickname?.trim() === 'undefined'
                ? ''
                : 'пользователем ' + response?.nickname
            }`,
          );
        }
      }

      const result = await callKloud({
        phoneNumber: phoneNumberWithCode,
        clientId: CLIENT_ID,
        providerId: provider_id,
      });

      if (result) {
        setIsCallKloud(true);
        setTime([1, 30]);
      }
    } catch (e) {
      console.log('handleCallKloudError ', e);
    }
  };

  const handleCheckKloudCode = async () => {
    if (getCodeError() || !kloudProvider) return;
    try {
      // const result = await checkKloudCode({
      //   phoneNumber: phoneNumberWithCode,
      //   code: code,
      //   clientId: CLIENT_ID,
      //   providerId: provider_id,
      // });

      // if (!result) {
      //   return setCodeError('Неверный код подтверждения');
      // }

      if (userId)
        await bindKloudAccount({
          userId,
          issuer: kloudProvider.params.issuer,
          code,
          rebind: externalAccountExists,
        }).unwrap();

      navigate('/profile');
    } catch (e) {
      console.log('handleCheckKloudCode', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['change-email']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Добавить Kloud.One
        </CustomTypography>
        {!isCallKloud ? (
          <>
            <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
              Введите номер телефона, чтобы получить звонок для авторизации
            </CustomTypography>
            <TextField
              value={phoneNumber}
              onChange={handleChange}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const phoneNumberErr = getPhoneNumberError();
                if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
              }}
              error={!!phoneNumberError}
              helperText={phoneNumberError && !externalAccountExists ? phoneNumberError : ''}
              fullWidth
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumericFormatCustom as any,
              }}
              variant="standard"
            />
            {externalAccountExists && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ color: '#d32f2f' }} className={'text-14'}>
                  {phoneNumberError}
                </Typography>
              </div>
            )}
            <CustomTypography className={clsx('text-14')} color="grey">
              На указаный номер поступит бесплатный звонок
            </CustomTypography>
          </>
        ) : (
          <>
            <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
              Введите 4 последние цифры входящего номера
            </CustomTypography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <CustomTypography className={clsx('text-14')} color="grey">
                  Позвонить повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </CustomTypography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={() => handleCallKloud()}
                >
                  Позвонить повторно
                </Button>
              )}
              <Button
                onClick={codeInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить номер
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={externalAccountExists && !isCallKloud}
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isCallKloud) {
                return handleCheckKloudCode();
              }
              handleCallKloud();
            }}
          >
            {isCallKloud ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const BindKloudAccount = connect(mapStateToProps)(BindKloudAccountComponent);
