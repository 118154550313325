import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './EventLog.module.css';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { debounce } from '@mui/material';
import { ReactComponent as ArrowDown } from '../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CheckAvatarIcon } from '../icons/CheckAvatar.svg';
import { ReactComponent as EmptySearchIcon } from '../icons/EmptySearch.svg';
import { ReactComponent as ExportIcon } from '../icons/Export.svg';
import { ReactComponent as ErrorAvatarIcon } from '../icons/ErrorSmall.svg';
import { ReactComponent as FilterIcon } from '../icons/Filter.svg';
import { ReactComponent as RemoveIcon } from '../icons/Close.svg';
import { ReactComponent as SearchIcon } from '../icons/Search.svg';
import { ReactComponent as RefreshIcon } from '../icons/Refresh.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { TAppSlice, setIsEventLogFilterOpen, setClientPanel } from '../redux/appSlice';
import {
  useLazyGetLogQuery,
  useLazyGetLogEventsCountQuery,
  TLogger,
} from '../redux/services/logger';
import { TUserSlice } from '../redux/userSlice';
import { exportToJson, isObjectEmpty, isAdministrator } from '../helpers';
import { EventLogRightPanel } from './EventLogRightPanel';
import { EventLogFilter } from './EventLogFilter';
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  RowMouseEventHandlerParams,
  Table,
  TableHeaderProps,
} from 'react-virtualized';
import { format } from 'date-fns';
import { SelectViewType } from './custom/SelectViewType';
import { CustomSelect } from './custom/CustomSelect';
import { CustomTypography } from './custom/CustomTypography';

type Order = 'asc' | 'desc';
type TColumnNames =
  | 'level'
  | 'date'
  | 'client_id'
  | 'user_id'
  | 'ip_address'
  | 'device'
  | 'event'
  | 'description';

type TEventLogProps = {
  userId?: string;
  isEventLogFilterOpen: TAppSlice['isEventLogFilterOpen'];
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  filter: TUserSlice['filter'];
  filterCount: TUserSlice['filterCount'];
  userRole?: string;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  isEventLogFilterOpen: state.app.isEventLogFilterOpen,
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
  clientPanelWidth: state.app.clientPanelWidth,
  filter: state.user.filter,
  filterCount: state.user.filterCount,
  userRole: state.user.userProfile.role,
});

const EventLogComponent: FC<TEventLogProps> = ({
  isClientPanelOpen,
  userId,
  isEventLogFilterOpen,
  isMobile,
  clientPanelWidth,
  filter,
  filterCount,
  userRole: role,
}) => {
  const dispatch = useDispatch();
  const [getLog, { isFetching: getLogFetching }] = useLazyGetLogQuery();
  const [getLogEventsCount] = useLazyGetLogEventsCountQuery();
  const [animateWidth, setAnimateWidth] = useState(false);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<TColumnNames>('date');
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const mounted = useRef(false);
  const [width, setWidth] = useState(0);
  const [events, setEvents] = useState<(Partial<TLogger> | undefined)[]>([]);
  const [eventsCount, setEventsCount] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState<Partial<TLogger> | null>(null);
  const [lastStartIndex, setLastStartIndex] = useState(0);
  const [logType, setLogType] = useState('user');
  const loadMoreRowsRef = useRef<null | (() => Promise<void>)>(null);
  const rowCount = events.length;
  const initialСolumnsCount = role !== '' ? 8 : 7;
  const columnsCount = Math.min(Math.round(width / 180), initialСolumnsCount);
  const columnWidth = width / columnsCount;
  const [searchValue, setSearchValue] = useState('');
  const logTypeVariants = [
    {
      name: 'Все',
      value: 'all',
    },
    {
      name: 'Пользователь',
      value: 'user',
    },
    {
      name: 'Приложения',
      value: 'editor',
    },
  ];
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);

  useEffect(() => {
    if (events.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [events]);

  useEffect(() => {
    if (!mounted.current) {
      setAnimateWidth(false);
      mounted.current = true;
    } else {
      setAnimateWidth(true);
      return () => setAnimateWidth(false);
    }
  }, [isClientPanelOpen]);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth - 31 - +!isMobile * (clientPanelWidth * +!isClientPanelOpen));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isClientPanelOpen, clientPanelWidth, isMobile]);

  const selectFirstEvent = async (searchString?: string) => {
    if (userId) {
      const { data: events } = await getLog({
        user_id: userId,
        log_type: logType,
        search_string: searchString === undefined ? searchValue : searchString,
        start_date: filter?.start_date || '',
        end_date: filter?.end_date || '',
        sort_by: orderBy || 'date',
        sort_direction: order,
        number_of_records: '1',
        number_of_skip: '0',
        search_param_level: filter?.level === 'ALL' ? '' : filter?.level || '',
        search_param_user_id: filter?.user_id || '',
        search_param_client_id: filter?.client_id || '',
        search_param_ip: filter?.ip_address || '',
        search_param_event: filter?.event || '',
        search_param_description: filter?.description || '',
      });

      if (events?.length) {
        setSelectedEvent(events[0]);
      } else {
        setSelectedEvent(null);
      }
    }
  };

  useEffect(() => {
    if (!getLogFetching) {
      loadMoreRowsRef.current?.();
      loadMoreRowsRef.current = null;
    }
  }, [getLogFetching]);

  useEffect(() => {
    setEventsOnStart();
    return () => setEvents([]);
  }, [userId, logType, filter]);

  useEffect(() => {
    if (events.length) {
      setEvents((events) => events.map(() => undefined));
      selectFirstEvent();
    }
  }, [order, orderBy]);

  const onSearch = async (searchString: string) => {
    if (userId) {
      const { data: eventsCount } = await getLogEventsCount({
        user_id: userId,
        log_type: logType,
        search_string: searchString,
        start_date: filter?.start_date || '',
        end_date: filter?.end_date || '',
        search_param_level: filter?.level === 'ALL' ? '' : filter?.level || '',
        search_param_user_id: filter?.user_id || '',
        search_param_client_id: filter?.client_id || '',
        search_param_ip: filter?.ip_address || '',
        search_param_event: filter?.event || '',
        search_param_description: filter?.description || '',
      });
      setEventsCount(eventsCount || 0);

      if (eventsCount !== undefined) {
        selectFirstEvent(searchString);
        setEvents(new Array(Number(eventsCount)).fill(undefined));
      }
    }
  };

  const setEventsOnStart = async () => {
    if (userId) {
      const { data: eventsCount } = await getLogEventsCount({
        user_id: userId,
        log_type: logType,
        search_string: searchValue,
        start_date: filter?.start_date || '',
        end_date: filter?.end_date || '',
        search_param_level: filter?.level === 'ALL' ? '' : filter?.level || '',
        search_param_user_id: filter?.user_id || '',
        search_param_client_id: filter?.client_id || '',
        search_param_ip: filter?.ip_address || '',
        search_param_event: filter?.event || '',
        search_param_description: filter?.description || '',
      });
      setEventsCount(eventsCount || 0);
      setEvents(new Array(Number(eventsCount)).fill(undefined));
      selectFirstEvent();
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 50), [logType, order, orderBy, filter]);
  const debounceRefreshLog = useCallback(debounce(setEventsOnStart, 50), [userId, logType, filter]);

  const rowGetter = ({ index }: { index: number }) => {
    return events?.[index] || {};
  };

  const onRowClick = (rowInfo: RowMouseEventHandlerParams) => {
    setSelectedEvent(rowInfo.rowData);
  };

  const isRowLoaded = ({ index }: { index: number }) => !!events[index];

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
    withoutLastId,
    ignoreLogLoading,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
    withoutLastId?: boolean;
    ignoreLogLoading?: boolean;
  }) => {
    if (!userId) return;
    if (!getLogFetching || ignoreLogLoading) {
      let newOrder = order;
      const newIndexlessThanLastIndex =
        startIndex < lastStartIndex && events?.[stopIndex + 1]?.id && !withoutLastId;

      if (newIndexlessThanLastIndex) newOrder = order === 'asc' ? 'desc' : 'asc';
      const { data } = await getLog({
        user_id: userId,
        log_type: logType,
        start_date: filter?.start_date || '',
        end_date: filter?.end_date || '',
        search_string: searchString === undefined ? searchValue : searchString,
        sort_by: orderBy || 'date',
        sort_direction: newOrder,
        number_of_records: String(stopIndex - startIndex + 1),
        number_of_skip: String(startIndex),
        last_record_id: withoutLastId
          ? ''
          : startIndex < lastStartIndex
          ? String(events?.[stopIndex + 1]?.id || '')
          : String(events?.[startIndex - 1]?.id || ''),
        search_param_level: filter?.level === 'ALL' ? '' : filter?.level || '',
        search_param_user_id: filter?.user_id || '',
        search_param_client_id: filter?.client_id || '',
        search_param_ip: filter?.ip_address || '',
        search_param_event: filter?.event || '',
        search_param_description: filter?.description || '',
      });

      const reversedEvents = newIndexlessThanLastIndex ? [...(data || [])].reverse() : data;

      setEvents((events) =>
        events.map((event, index) => {
          if (index < startIndex || index > stopIndex) return event;
          return reversedEvents?.[index - startIndex];
        }),
      );
      setLastStartIndex(startIndex);
    } else {
      loadMoreRowsRef.current = () =>
        loadMoreRows({ startIndex, stopIndex, searchString, ignoreLogLoading: true });
    }
  };

  const debouncedLoadMoreRows = debounce(loadMoreRows, 100);

  const downloadLog = async () => {
    if (userId) {
      const { data } = await getLog({
        user_id: userId,
        log_type: logType,
        start_date: filter?.start_date || '',
        end_date: filter?.end_date || '',
        search_string: searchValue,
        sort_by: orderBy || 'date',
        sort_direction: order,
        number_of_records: String(eventsCount),
        search_param_level: filter?.level === 'ALL' ? '' : filter?.level || '',
        search_param_user_id: filter?.user_id || '',
        search_param_client_id: filter?.client_id || '',
        search_param_ip: filter?.ip_address || '',
        search_param_event: filter?.event || '',
        search_param_description: filter?.description || '',
      });

      return exportToJson({ ...data }, 'log.json');
    }
  };

  const getRowClassName = ({ index }: { index: number }) => {
    return clsx(styles['table-row'], styles['flex-container'], {
      [styles['content-row']]: index !== -1,
      [styles['selected-row']]: selectedEvent?.id === rowGetter?.({ index })?.id && index !== -1,
    });
  };

  const handleRequestSort = (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerRenderer = ({ label, dataKey }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(styles['table-cell'], styles['flex-container'], styles['header-cell'])}
        variant="head"
        align="left"
      >
        <Button
          onClick={() => handleRequestSort(dataKey as TColumnNames)}
          className={styles['header-button']}
        >
          <CustomTypography
            style={{ maxWidth: columnWidth - 40 }}
            className={clsx(styles['overflow-ellipsis'], 'text-14')}
            color="grey"
          >
            {label}
          </CustomTypography>
        </Button>
        {dataKey === orderBy && (
          <ArrowTopIcon className={clsx({ [styles['rotate-180']]: !(order === 'asc') })} />
        )}
      </TableCell>
    );
  };

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };

  const onFilterIconClick = () => {
    dispatch(setIsEventLogFilterOpen(!isEventLogFilterOpen));
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.bar}>
        <TextField
          className={clsx(styles.search, 'custom')}
          placeholder="Поиск"
          variant="standard"
          value={searchValue}
          onChange={(e) => {
            onSearchDebounce(e.target.value);
            setSearchValue(e.target.value);
          }}
          inputProps={{ className: styles.input }}
          InputProps={{
            startAdornment: <SearchIcon className={styles['search-icon']} />,
            endAdornment: (
              <IconButton
                onClick={() => {
                  onSearch('');
                  setSearchValue('');
                }}
              >
                {searchValue && <RemoveIcon />}
              </IconButton>
            ),
          }}
        />
        <div className={styles['action-section']}>
          {role !== '' && (
            <CustomSelect value={logType} onChange={(e) => setLogType(e.target.value)}>
              {logTypeVariants
                .slice(role === 'OWNER' ? 0 : 1, isAdministrator(role) ? 3 : -1)
                .map((variant) => (
                  <MenuItem className="custom-select" key={variant.value} value={variant.value}>
                    {variant.name}
                  </MenuItem>
                ))}
            </CustomSelect>
          )}
          <Tooltip placement="bottom-end" arrow title="Обновить">
            <Button
              disabled={getLogFetching}
              onClick={debounceRefreshLog}
              variant="custom"
              color="secondary"
              className={styles['refresh-button']}
              sx={(theme) => ({
                '& path': {
                  fill: theme.palette.custom.main,
                },
              })}
              endIcon={<RefreshIcon className={clsx({ [styles.rotate]: getLogFetching })} />}
              tabIndex={-1}
            />
          </Tooltip>
          <Tooltip placement="bottom-end" arrow title="Экспортировать">
            <Button
              disabled={!eventsCount}
              onClick={downloadLog}
              variant="custom"
              color="secondary"
              className={styles['download-button']}
              endIcon={<ExportIcon />}
              tabIndex={-1}
            />
          </Tooltip>
          <Tooltip placement="bottom-end" arrow title="Фильтр">
            <Button
              onClick={onFilterIconClick}
              variant="custom"
              color="secondary"
              className={styles['filter-button']}
              sx={(theme) => ({
                '&.MuiButton-root': {
                  padding: 0,
                },
                '& path': {
                  stroke: theme.palette.custom.main,
                },
              })}
            >
              <FilterIcon />
              {filterCount ? <div className={styles['filter-count']}>{filterCount}</div> : ''}
            </Button>
          </Tooltip>
          <Popover
            classes={{ paper: styles['view-popover-paper'] }}
            onClose={() => setViewAnchorEl(null)}
            anchorEl={viewAnchorEl}
            open={!!viewAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
          </Popover>
          {!isMobile && (
            <Button
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                setViewAnchorEl(event.currentTarget)
              }
              className={styles.view}
              classes={{ endIcon: styles['view-icon'] }}
              endIcon={<ArrowDown fill="#9DA2B3" />}
            >
              <CustomTypography className={clsx('text-14')} color="grey">
                Вид
              </CustomTypography>
            </Button>
          )}
        </div>
      </Box>
      <Box className={styles.content}>
        {eventsCount ? (
          <Box
            className={clsx(styles['log-table'], {
              [styles['full-width']]: isClientPanelOpen,
            })}
          >
            <div
              className={clsx(styles['log-table-wrapper'], {
                [styles['animate-width']]: animateWidth,
              })}
            >
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isRowLoaded={isRowLoaded}
                loadMoreRows={debouncedLoadMoreRows}
                rowCount={rowCount}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <AutoSizer>
                      {({ height }) => {
                        return (
                          <Table
                            gridClassName={'events-table__grid'}
                            id={'table'}
                            height={height}
                            width={width}
                            rowHeight={56}
                            headerHeight={56}
                            rowCount={rowCount}
                            rowGetter={rowGetter}
                            rowClassName={getRowClassName}
                            onRowClick={onRowClick}
                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                          >
                            <Column
                              label="Уровень"
                              width={columnWidth}
                              headerRenderer={(headerProps) =>
                                headerRenderer({
                                  ...headerProps,
                                  columnIndex: 0,
                                })
                              }
                              className={styles['flex-container']}
                              cellRenderer={({ cellData, rowData }) => {
                                return (
                                  <TableCell
                                    className={clsx(styles['table-cell'], styles['flex-container'])}
                                    component="div"
                                    variant="body"
                                    align="left"
                                  >
                                    {isObjectEmpty(rowData) ? (
                                      <Skeleton width={24} style={{ marginRight: 16 }} />
                                    ) : cellData === 'INFO' ? (
                                      <CheckAvatarIcon className={styles['event-avatar']} />
                                    ) : (
                                      <ErrorAvatarIcon className={styles['event-avatar']} />
                                    )}
                                    <CustomTypography
                                      style={{
                                        width: 'auto',
                                        maxWidth: columnWidth - 80,
                                        transition: '0.5s ease all',
                                      }}
                                      className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                    >
                                      {isObjectEmpty(rowData) ? (
                                        <Skeleton width={width - 64} />
                                      ) : cellData === 'INFO' ? (
                                        'Информация'
                                      ) : (
                                        'Ошибка'
                                      )}
                                    </CustomTypography>
                                  </TableCell>
                                );
                              }}
                              dataKey="level"
                            />
                            {columnsCount > 1 && (
                              <Column
                                label="Дата и время"
                                width={columnWidth}
                                key="date"
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 1,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  const date = new Date(cellData);

                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          format(date, 'y-MM-dd HH:mm:ss')
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="date"
                              />
                            )}
                            {columnsCount > 2 && (
                              <Column
                                label="Приложение"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 2,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="client_id"
                              />
                            )}
                            {columnsCount > 3 && role !== '' && (
                              <Column
                                label="Пользователь"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 3,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="user_id"
                              />
                            )}
                            {columnsCount > (role !== '' ? 4 : 3) && (
                              <Column
                                label="Местоположение (IP)"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 4,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData?.replace('::ffff:', '')
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="ip_address"
                              />
                            )}
                            {columnsCount > (role !== '' ? 5 : 4) && (
                              <Column
                                label="Устройство"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 5,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="device"
                              />
                            )}
                            {columnsCount > (role !== '' ? 6 : 5) && (
                              <Column
                                label="Событие"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 6,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="event"
                              />
                            )}
                            {columnsCount > (role !== '' ? 7 : 6) && (
                              <Column
                                label="Описание"
                                width={columnWidth}
                                headerRenderer={(headerProps) =>
                                  headerRenderer({
                                    ...headerProps,
                                    columnIndex: 7,
                                  })
                                }
                                className={styles['flex-container']}
                                cellRenderer={({ cellData, rowData }) => {
                                  return (
                                    <TableCell
                                      className={clsx(
                                        styles['table-cell'],
                                        styles['flex-container'],
                                      )}
                                      component="div"
                                      variant="body"
                                      align="left"
                                    >
                                      <CustomTypography
                                        style={{
                                          width: 'auto',
                                          maxWidth: columnWidth - 40,
                                          transition: '0.5s ease all',
                                        }}
                                        className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                      >
                                        {isObjectEmpty(rowData) ? (
                                          <Skeleton width={width - 40} />
                                        ) : (
                                          cellData
                                        )}
                                      </CustomTypography>
                                    </TableCell>
                                  );
                                }}
                                dataKey="description"
                              />
                            )}
                          </Table>
                        );
                      }}
                    </AutoSizer>
                  );
                }}
              </InfiniteLoader>
            </div>
          </Box>
        ) : (
          <Box className={styles.empty}>
            <div className={styles['empty-inner']}>
              <EmptySearchIcon className={styles['empty-icon']} />
              <CustomTypography className={clsx('text-17-regular', 'font-golos')} color="grey">
                По вашему запросу ничего не найдено
              </CustomTypography>
            </div>
          </Box>
        )}
        {eventsCount && <EventLogRightPanel selectedEvent={selectedEvent} />}
        <EventLogFilter role={role} />
      </Box>
    </Box>
  );
};

export const EventLog = connect(mapStateToProps)(EventLogComponent);
