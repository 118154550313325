import React, { FC, useEffect, useState } from 'react';
import styles from './UserEvent.module.css';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { TLogger, useLazyGetUserActivityQuery } from '../../redux/services/logger';
import { format } from 'date-fns';
import { CustomTypography } from '../custom/CustomTypography';

const UserEvent: FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [getUserActivity] = useLazyGetUserActivityQuery();
  const [selectedEvent, setSelectedEvent] = useState<Partial<TLogger> | null>(null);

  useEffect(() => {
    const getEvent = async () => {
      if (eventId && userId) {
        const { data: events } = await getUserActivity({
          user_id: userId,
          log_type: 'user',
          search_string: eventId,
        });

        if (events?.length) setSelectedEvent(events[0]);
      }
    };
    getEvent();
  }, [eventId, userId]);

  return (
    <div className={'wrapper'}>
      <div className={'content'}>
        <CustomTypography
          style={{ marginBottom: 32 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Информация о событии
        </CustomTypography>
        <Box className={styles.panel}>
          <CustomTypography className={clsx('header-3', 'font-golos', styles['title'])}>
            {!selectedEvent ? (
              <Skeleton variant="text" width="20%" />
            ) : (
              `Вход в ${selectedEvent?.name}`
            )}
          </CustomTypography>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
                Дата
              </CustomTypography>
              <div className={styles['value-wrap']}>
                {!selectedEvent?.date ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {format(new Date(selectedEvent.date), 'y-MM-dd HH:mm:ss')}
                  </CustomTypography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
                Местоположение
              </CustomTypography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedEvent?.ip_address?.replace('::ffff:', '')}
                  </CustomTypography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
                Устройство
              </CustomTypography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedEvent?.device}
                  </CustomTypography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <CustomTypography className={clsx('text-14', styles['info-item-title'])} color="grey">
                Описание
              </CustomTypography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <CustomTypography className={clsx('text-14', styles['info-item-value'])}>
                    {selectedEvent?.description}
                  </CustomTypography>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default UserEvent;
