import styles from './Applications.module.css';
import React, { FC, MouseEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import clsx from 'clsx';
import { RootState } from '../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { isAdministrator } from '../../helpers';
import { ApplicationsTable } from './ApplicationsTable';
import { ApplicationRightPanel } from './ApplicationRightPanel';
import { SelectViewType } from '../custom/SelectViewType';
import { setClientPanel, TAppSlice } from '../../redux/appSlice';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';
import { Tooltip } from '@mui/material';

type TApplicationsProps = {
  userId?: string;
  userRole?: string;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
});

const ApplicationsComponent: FC<TApplicationsProps> = ({
  userRole,
  isClientPanelOpen,
  isMobile,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectString = params.get('redirect'); // bar
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };
  return (
    <Box className={styles.applications}>
      <Box className={styles['tabs-wrapper']}>
        <Popover
          classes={{ paper: styles['view-popover-paper'] }}
          onClose={() => setViewAnchorEl(null)}
          anchorEl={viewAnchorEl}
          open={!!viewAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
        </Popover>
        {isAdministrator(userRole) && (
          <Tooltip placement="bottom-end" arrow title="Создать приложение">
            <Button
              variant="custom"
              color="secondary"
              className={styles['add-button']}
              onClick={() => {
                navigate('/applications/create');
              }}
              startIcon={<LibraryAddOutlinedIcon />}
            />
          </Tooltip>
        )}
        {!isMobile && (
          <Button
            onClick={(event: MouseEvent<HTMLButtonElement>) => setViewAnchorEl(event.currentTarget)}
            className={styles.view}
            classes={{ endIcon: styles['view-icon'] }}
            endIcon={<ArrowDown fill="#9DA2B3" />}
          >
            <CustomTypography className={clsx('text-14')} color="grey">
              Вид
            </CustomTypography>
          </Button>
        )}
      </Box>
      {isAdministrator(userRole) && (
        <div className={styles['tab-container']}>
          <ApplicationsTable variant="admin" />
          <ApplicationRightPanel variant="admin" />
        </div>
      )}
    </Box>
  );
};

export const Applications = connect(mapStateToProps)(ApplicationsComponent);
