import React, {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './UsersControl.module.css';
import clsx from 'clsx';
import {
  TClient,
  TUser,
  useChangeUsersRoleMutation,
  useGetUserCountQuery,
  useLazyGetUserCountQuery,
  useLazyGetUsersQuery,
} from '../../redux/services/client';
import { setClientPanel, TAppSlice } from '../../redux/appSlice';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { Autocomplete, CircularProgress, MenuItem, Tooltip, debounce } from '@mui/material';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ExportIcon } from '../../icons/Export.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as UnlockIcon } from '../../icons/Unlock.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';

import {
  AutoSizer,
  Column,
  InfiniteLoader,
  RowMouseEventHandlerParams,
  Table,
  TableHeaderProps,
} from 'react-virtualized';
import { exportToJson, getImageURL, getRoleName, isObjectEmpty } from '../../helpers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CustomRadioButton } from '../CustomRadioButton';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { Roles } from '../../enums';
import { CustomCheckbox } from '../custom/CustomCheckbox';
import 'date-fns';
import ru from 'date-fns/locale/ru';
import { format } from 'date-fns';
import { TUserProfile } from '../../redux/userSlice';
import {
  useBlockUsersMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useGetActiveUsersCountMutation,
  useUnblockUsersMutation,
} from '../../redux/services/owner';
import { SelectViewType } from '../custom/SelectViewType';
import { UsersRightPanel } from './UsersRightPanel';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { setNotice } from '../../redux/noticesSlice';
import { CustomButton } from '../custom/CustomButton';
import { UsersControlMultipleOperations } from './UsersControlMultipleOperations';
import { CustomSelect } from '../custom/CustomSelect';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { useGetSettingsQuery } from '../../redux/services/settings';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { CustomTypography } from '../custom/CustomTypography';
import {
  SearchHistoryTypes,
  useDeleteSearchHistoryMutation,
  useGetSearchHistoryQuery,
} from 'src/redux/services/user';

export type Order = 'asc' | 'desc';
export type TColumnNames = 'mainPage' | 'user' | 'role';
export type TUsersControlProps = {
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  isMobile: TAppSlice['isMobile'];
  owner: TUserProfile;
};

const mapStateToProps = (state: RootState) => ({
  clientPanelWidth: state.app.clientPanelWidth,
  isMobile: state.app.isMobile,
  owner: state.user.userProfile,
  isClientPanelOpen: state.app.isClientPanelOpen,
});

const UsersControlComponent: FC<TUsersControlProps> = ({
  isClientPanelOpen,
  clientPanelWidth,
  isMobile,
  owner,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [orderBy, setOrderBy] = useState<TColumnNames>('user');
  const [users, setUsers] = useState<({ user: Partial<TUser>; role: string } | undefined)[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserToActions, setSelectedUserToActions] = useState<{
    user: Partial<TUser>;
    role: string;
  } | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [width, setWidth] = useState(0);
  const [animateWidth, setAnimateWidth] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<
    Roles.ADMIN | Roles.OWNER | Roles.USER | null
  >(null);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState<{ user: TUser; role: string }[]>([]);
  const [uncheckedUsers, setUncheckedUsers] = useState<{ user: TUser; role: string }[]>([]);
  const [applicationsToDelete, setApplicationsToDelete] = useState<TClient[]>([]);
  const [allUsersChecked, setAllUsersChecked] = useState(false);
  const [blockUsersModalOpen, setBlockUsersModalOpen] = useState(false);
  const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false);
  const [deleteUsersSessionsModalOpen, setDeleteUsersSessionsModalOpen] = useState(false);
  const [deleteAppsWithUser, setDeleteAppsWithUser] = useState(false);
  const [searchFieldTooltipOpen, setSearchFieldsTooltipOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedSearchFields, setSelectedSearchFields] = useState<string[]>([]);
  const [deletingSearchFields, setDeletingSearchFields] = useState<string[]>([]);
  const [searchHistoryLoading, setSearchHistoryLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<{ user: Partial<TUser>; role: string } | null>(
    null,
  );

  const isOpen = Boolean(anchorEl);
  const usersWithoutOwner = users.filter(
    (user) => String(user?.user.id) !== owner.id && user?.user.id !== 1,
  );
  const userToActions = selectedUserToActions?.user;
  const dispatch = useDispatch();
  const rowCount = users.length;
  const columnsCount = Math.min(3, Math.round(width / 350));
  const sidePaddings = 36;
  const lastColumnWidth = 40;
  const columnWidth = (width - sidePaddings - lastColumnWidth) / columnsCount;
  const mounted = useRef(false);
  const loadMoreRowsRef = useRef<null | (() => Promise<void>)>(null);
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const loaderWrapperRef = useRef<null | HTMLDivElement>(null);
  const saveParamsRef = useRef<{
    scrollTop?: number;
    searchValue?: string;
    order?: string;
    orderBy?: string;
    selectedSearchFields?: string[];
    checkedUsers?: { user: TUser; role: string }[];
    uncheckedUsers?: { user: TUser; role: string }[];
    allUsersChecked?: boolean;
    selectedUser?: { user: Partial<TUser>; role: string } | null;
  }>({});
  const searchFieldTypeVariants = [
    {
      name: 'id',
      value: 'id',
    },
    {
      name: 'Почта',
      value: 'email',
    },
    {
      name: 'Никнейм',
      value: 'nickname',
    },
    {
      name: 'Имя',
      value: 'given_name',
    },
    {
      name: 'Фамилия',
      value: 'family_name',
    },
    {
      name: 'Номер телефона',
      value: 'phone_number',
    },
    {
      name: 'Логин',
      value: 'login',
    },
  ];

  const { data: dataSettings } = useGetSettingsQuery();
  const user = useSelector((state: RootState) => state.user.userProfile);
  const {
    data: searchHistory,
    refetch: refetchSearchHistory,
    isFetching: searchHistoryFetching,
  } = useGetSearchHistoryQuery({ userId: user.id || '', type: SearchHistoryTypes.users }) || [];
  const { data: dataCount, refetch: refetchUsersCount } = useGetUserCountQuery({
    selectedAppId: CLIENT_ID,
    search_string: '',
  });
  const [getUsers, { isFetching: getUsersFetching }] = useLazyGetUsersQuery();
  const [getUserCount, { isFetching: getUsersCountFetching }] = useLazyGetUserCountQuery();
  const [changeUsersRole, { isLoading: changeUsersRoleLoading }] = useChangeUsersRoleMutation();
  const [blockUsers, { isLoading: blockUsersLoading }] = useBlockUsersMutation();
  const [unblockUsers, { isLoading: unblockUsersLoading }] = useUnblockUsersMutation();
  const [deleteUsers, { isLoading: deleteUsersLoading }] = useDeleteUsersMutation();
  const [deleteSearchHistory] = useDeleteSearchHistoryMutation();

  const [getDeleteUsersInfo, { data: deleteUsersInfoData, isLoading: deleteUsersInfoLoading }] =
    useDeleteUsersInfoMutation();
  const [deleteUsersSessions, { isLoading: deleteUsersSessionsLoading }] =
    useDeleteUsersSessionsMutation();
  const [getActiveUsersCount, { isLoading: getActiveUsersCountLoading, data: activeUsersCount }] =
    useGetActiveUsersCountMutation();
  const [selectedSearchFieldsBeforeChange, setSelectedSearchFieldsBeforeChange] = useState<
    string[]
  >([]);
  const disableButtons =
    blockUsersLoading || unblockUsersLoading || deleteUsersLoading || changeUsersRoleLoading;
  const actionPopoverDisabled =
    getUsersFetching ||
    changeUsersRoleLoading ||
    blockUsersLoading ||
    unblockUsersLoading ||
    deleteUsersLoading ||
    deleteUsersInfoLoading ||
    getUsersCountFetching;
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (users.length) {
      const savedParams = JSON.parse(sessionStorage.getItem('savedParams') || 'null');
      if (savedParams) {
        setCheckedUsers(savedParams.checkedUsers || []);
        setUncheckedUsers(savedParams.uncheckedUsers || []);
        if (savedParams.allUsersChecked || savedParams.uncheckedUsers) {
          getActiveUsersCount({ search_string: savedParams.searchValue }).unwrap();
          setAllUsersChecked(savedParams.allUsersChecked);
        }
        if (savedParams.selectedUser) {
          setSelectedUser(savedParams.selectedUser);
        }
        if (savedParams.scrollTop) {
          const table = loaderWrapperRef.current?.querySelector('.users-table__grid');
          table?.scrollTo({
            top: savedParams.scrollTop,
          });
        }
      }
      return true;
    }
    return false;
  }, [users]);

  useEffect(() => {
    if (!searchHistoryFetching) setSearchHistoryLoading(false);
  }, [searchHistoryFetching]);

  useEffect(() => {
    if (!mounted.current) {
      setAnimateWidth(false);
      mounted.current = true;
    } else {
      setAnimateWidth(true);
      return () => setAnimateWidth(false);
    }
  }, [isClientPanelOpen]);

  useEffect(() => {
    if (users[0] && !selectedUser) setSelectedUser(users[0]);
  }, [users]);

  useEffect(() => {
    const savedParams = JSON.parse(sessionStorage.getItem('savedParams') || 'null');
    if (savedParams) {
      const setUsersOnStart = async () => {
        setSearchValue(savedParams.searchValue);
        setOrder(savedParams.order);
        setOrderBy(savedParams.orderBy);
        setSelectedSearchFields(savedParams.selectedSearchFields);

        const { data: countData } = await getUserCount({
          selectedAppId: CLIENT_ID,
          search_string: savedParams.searchValue,
          search_filter:
            !savedParams.selectedSearchFields.length || !savedParams.searchString
              ? ['all']
              : savedParams.selectedSearchFields,
        });

        setUsers(new Array(Number(countData?.userCount)).fill(undefined));
      };
      setUsersOnStart();
    } else {
      const setUsersOnStart = async () => {
        setSearchValue('');
        const { data: countData } = await getUserCount({
          selectedAppId: CLIENT_ID,
          search_string: '',
        });

        setUsers(new Array(Number(countData?.userCount)).fill(undefined));
      };
      setUsersOnStart();
    }

    const handleUnload = () => {
      sessionStorage.setItem(
        'savedParams',
        JSON.stringify({
          ...saveParamsRef.current,
        }),
      );
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => {
      handleUnload();
      window.removeEventListener('beforeunload', handleUnload);
      setUsers([]);
    };
  }, []);

  useEffect(() => {
    setDeletingSearchFields((fields) => fields.filter((field) => !!searchHistory?.includes(field)));
  }, [searchHistory]);

  useEffect(() => {
    if (users.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [users]);

  useEffect(() => {
    clearUsers();
  }, [order, orderBy]);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth - 31 - +!isMobile * (clientPanelWidth * +!isClientPanelOpen));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isClientPanelOpen, clientPanelWidth, isMobile]);

  useEffect(() => {
    if (
      !selectOpen &&
      searchValue &&
      !(
        selectedSearchFieldsBeforeChange.every((selectedBefore) =>
          selectedSearchFields.some((selected) => selected === selectedBefore),
        ) &&
        selectedSearchFields.every((selected) =>
          selectedSearchFieldsBeforeChange.some((selectedBefore) => selectedBefore === selected),
        )
      )
    )
      onSearch(searchValue);
  }, [selectOpen]);

  useEffect(() => {
    if (!getUsersFetching) {
      loadMoreRowsRef.current?.();
      loadMoreRowsRef.current = null;
    }
  }, [getUsersFetching]);

  useEffect(() => {
    saveParamsRef.current = {
      ...saveParamsRef.current,
      searchValue,
      order,
      orderBy,
      selectedSearchFields,
      checkedUsers,
      allUsersChecked,
      uncheckedUsers,
      selectedUser,
    };
  }, [
    searchValue,
    order,
    orderBy,
    selectedSearchFields,
    checkedUsers,
    allUsersChecked,
    uncheckedUsers,
    selectedUser,
  ]);

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };

  const onSearch = async (searchString: string) => {
    setAllUsersChecked(false);
    setUncheckedUsers([]);
    setCheckedUsers([]);
    const { data: countData } = await getUserCount({
      selectedAppId: CLIENT_ID,
      search_string: searchString,
      search_filter: !selectedSearchFields.length || !searchString ? ['all'] : selectedSearchFields,
    });

    if (!searchString) refetchSearchHistory();

    if (countData !== undefined) {
      const { userCount } = countData;
      setUsers(new Array(Number(userCount)).fill(undefined));
    }
  };

  const clearUsers = async (refreshUserCount?: boolean) => {
    setCheckedUsers([]);
    setUncheckedUsers([]);
    setAllUsersChecked(false);
    setSelectedUser(null);
    if (refreshUserCount) {
      const count = await getUserCount({
        selectedAppId: CLIENT_ID,
        search_string: searchValue,
      }).unwrap();

      if (count !== undefined) {
        setUsers(new Array(Number(count.userCount)).fill(undefined));
      }
    } else {
      setUsers((users) => users.map(() => undefined));
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 700), [
    CLIENT_ID,
    order,
    orderBy,
    allUsersChecked,
    selectedSearchFields,
  ]);

  const rowGetter = ({ index }: { index: number }) => {
    return users?.[index] || {};
  };

  const onRowClick = (rowInfo: RowMouseEventHandlerParams) => {
    if (!isObjectEmpty(rowInfo.rowData) && rowInfo.rowData.user.id !== owner.id)
      setSelectedUser(rowInfo.rowData);
  };

  const isRowLoaded = ({ index }: { index: number }) => !!users[index];

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
    ignoreUsersLoading,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
    ignoreUsersLoading?: boolean;
  }) => {
    const searchValueToSend = searchString === undefined ? searchValue : searchString;

    if (!getUsersFetching || ignoreUsersLoading) {
      const { data } = await getUsers({
        client_id: CLIENT_ID,
        number_of_records: String(stopIndex - startIndex + 1),
        number_of_skip: String(startIndex),
        search_filter:
          !selectedSearchFields.length || !searchValueToSend ? ['all'] : selectedSearchFields,
        sort_by: orderBy === 'user' ? 'nickname' : orderBy,
        sort_direction: order,
        search_string: searchValueToSend,
      });

      setUsers((users) =>
        users.map((user, index) => {
          if (index < startIndex || index > stopIndex) return user;
          return data?.[index - startIndex];
        }),
      );
    } else {
      loadMoreRowsRef.current = () =>
        loadMoreRows({ startIndex, stopIndex, searchString, ignoreUsersLoading: true });
    }
  };

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    const userId = (rowGetter?.({ index }) as { user: TUser }).user?.id;

    return clsx(styles.row, {
      [styles['content-row']]: index !== -1,
      [styles['header-row']]: index === -1,
      [styles['selected-row']]:
        ((selectedUser && selectedUser.user.id === userId) ||
          !!checkedUsers.find((user) => user.user.id === userId) ||
          (uncheckedUsers.length &&
            !uncheckedUsers.find((user) => user.user.id === userId) &&
            String(userId) !== owner.id &&
            userId !== 1) ||
          (allUsersChecked && String(userId) !== owner.id && userId !== 1)) &&
        index !== -1,
      [styles['selected-checked-row']]:
        selectedUser &&
        selectedUser.user.id === userId &&
        !!checkedUsers.find((user) => user.user.id === userId),
    });
  };

  const handleRequestSort = (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenPopover = (
    event: MouseEvent<HTMLButtonElement>,
    user: { user: Partial<TUser>; role: string },
  ) => {
    event.stopPropagation();
    if (event.clientY + 180 > document.body.clientHeight) setPopoverOriginTop(true);
    else setPopoverOriginTop(false);
    setSelectedUserToActions(user);
    setAnchorEl(event.currentTarget);
  };

  const handleCheckUser = (user: { user: TUser; role: string }) => {
    if (allUsersChecked || uncheckedUsers.length) {
      if (uncheckedUsers.find((uncheckedUser) => uncheckedUser.user.id === user?.user?.id)) {
        if (uncheckedUsers.length === 1) setAllUsersChecked(true);
        setUncheckedUsers(
          uncheckedUsers.filter((uncheckedUser) => uncheckedUser.user.id !== user?.user?.id),
        );
      } else {
        setUncheckedUsers((users) => [...users, user]);
        setAllUsersChecked(false);
      }

      return;
    }

    if (checkedUsers.find((checkedUser) => checkedUser.user.id === user?.user?.id)) {
      setCheckedUsers(checkedUsers.filter((checkedUser) => checkedUser.user.id !== user?.user?.id));
    } else {
      setCheckedUsers((users) => [...users, user]);
    }
  };

  const userChecked = (user: { user: TUser; role: string }) => {
    if (allUsersChecked || uncheckedUsers.length) {
      return !uncheckedUsers.find((uncheckedUser) => uncheckedUser.user.id === user?.user?.id);
    }
    return !!checkedUsers.find((checkedUser) => checkedUser.user.id === user?.user?.id);
  };

  const handleCheckAllUsers = async () => {
    try {
      if (checkedUsers.length === usersWithoutOwner.length) return setCheckedUsers([]);
      setCheckedUsers([]);
      setUncheckedUsers([]);
      if (!allUsersChecked) {
        await getActiveUsersCount({ search_string: searchValue }).unwrap();
      }
      setAllUsersChecked((checked) => !checked);
    } catch (e) {
      console.log('handleCheckAllUsers error:', e);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSaveRole = async () => {
    try {
      if (!selectedPermission || !userToActions?.id) return;
      handleClosePopover();
      if (
        CLIENT_ID &&
        userToActions?.id &&
        users.find((user) => userToActions.id === user?.user.id)?.role !== selectedPermission
      ) {
        await changeUsersRole({
          checked_ids: [userToActions.id],
          role: selectedPermission,
          client_id: CLIENT_ID,
        }).unwrap();

        clearUsers();
        setChangePermissionsModalOpen(false);

        setSelectedPermission(null);

        dispatch(
          setNotice({
            id: Math.random(),
            isRead: false,
            message: `Права ${userToActions?.nickname} в ${PROJECT_NAME} изменены.`,
            timestamp: new Date().toString(),
            avatar: userToActions?.picture,
          }),
        );

        await onSearchDebounce(searchValue);
      }
    } catch (e) {
      console.log('handleSaveRole error:', e);
    }
  };

  const handleDeleteUsersSessions = async () => {
    try {
      if (!userToActions?.id) return;
      handleClosePopover();
      await deleteUsersSessions({
        checked_ids: [userToActions.id],
      }).unwrap();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Сеансы ${userToActions.nickname} завершены.`,
          timestamp: new Date().toString(),
          avatar: userToActions.picture,
        }),
      );

      setDeleteUsersSessionsModalOpen(false);
    } catch (e) {
      console.log('handleDeleteUsersSessions error:', e);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!userToActions?.id) return;
      handleClosePopover();
      const isBlock = !userToActions.blocked;
      if (isBlock)
        await blockUsers({
          checked_ids: [userToActions.id],
        }).unwrap();
      else
        await unblockUsers({
          checked_ids: [userToActions.id],
        }).unwrap();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Пользователь ${userToActions?.nickname} ${
            isBlock ? 'заблокирован' : 'разблокирован'
          } в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: userToActions?.picture,
        }),
      );

      setBlockUsersModalOpen(false);
      clearUsers();
    } catch (e) {
      console.log('handleBlockUser error:', e);
    }
  };

  const getUserStatus = (user?: TUser) => {
    if (user?.blocked)
      return (
        <CustomTypography className={clsx('text-14')} color="error">
          Заблокированный
        </CustomTypography>
      );
    const deleted = user?.deleted;
    if (deleted) {
      const deleteDate = new Date(deleted);
      deleteDate.setDate(deleteDate.getDate() + 30);

      return (
        <CustomTypography className={clsx('text-14')} color="grey">
          Будет удален {format(deleteDate, 'dd MMMM y г. в HH:mm', { locale: ru })}
        </CustomTypography>
      );
    }

    return <CustomTypography className="text-14">Активный</CustomTypography>;
  };

  const radioButtonSelected = (role: Roles.USER | Roles.ADMIN | Roles.OWNER) => {
    if (selectedPermission) return selectedPermission === role;
    return selectedUserToActions?.role === role;
  };

  const handleDeleteButton = async () => {
    try {
      handleClosePopover();
      if (!userToActions?.id) return;
      if (String(userToActions.id) === owner.id) return navigate('profile/delete');

      const deleteUsersInfo = await getDeleteUsersInfo({
        checked_ids: [userToActions.id],
      }).unwrap();
      setApplicationsToDelete(
        deleteUsersInfo.apps.filter((app) => app.onlyEditor).map((app) => app.client),
      );
      setDeleteUsersModalOpen(true);
    } catch (e) {
      console.log('handleDeleteButton error', e);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (!userToActions?.id) return;
      await deleteUsers({
        delete_apps_with_user: deleteAppsWithUser,
        apps_ids: deleteAppsWithUser
          ? deleteUsersInfoData?.apps.map(({ client }) => client.client_id)
          : deleteUsersInfoData?.apps
              .filter((app) => !app.onlyEditor)
              .map(({ client }) => client.client_id),
        checked_ids: [userToActions.id],
      }).unwrap();

      const id: string =
        userToActions.nickname || userToActions.login || userToActions.id.toString();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Пользователь ${id} удален в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: userToActions.picture,
        }),
      );
      clearUsers(true);

      refetchUsersCount();

      setDeleteUsersModalOpen(false);
    } catch (e) {
      console.log('handleDeleteUsers error', e);
    }
  };

  const handleUpdateUsersList = async () => {
    const { data: countData } = await getUserCount({
      selectedAppId: CLIENT_ID,
      search_string: '',
    });

    setUsers(new Array(Number(countData?.userCount)).fill(undefined));
  };

  const changePermissionsModal = () => (
    <Modal open={changePermissionsModalOpen} onClose={closeChangePermissionModal}>
      <div className={styles['modal-wrapper']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            Изменить права{' '}
          </CustomTypography>
          <IconButton
            onClick={closeChangePermissionModal}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-14')}>
          Выберите права в {PROJECT_NAME} для пользователя:
        </CustomTypography>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
          <Avatar src={getImageURL(userToActions?.picture)} className={styles.avatar}>
            {!userToActions?.picture && (
              <CustomTypography className={clsx('text-14')}>
                {userToActions?.nickname
                  ?.split(' ')
                  .map((name: string) => name[0]?.toUpperCase())
                  .join('')}
              </CustomTypography>
            )}
          </Avatar>
          <CustomTypography className={clsx('text-14')}>{userToActions?.nickname}</CustomTypography>
        </div>
        <CustomTypography style={{ marginBottom: 16 }} className={clsx('text-15-medium')}>
          Уровень полномочий
        </CustomTypography>
        {!dataSettings?.allow_everyone_to_create_applications && (
          <>
            <CustomRadioButton
              label="Участник"
              checked={radioButtonSelected(Roles.USER)}
              onClick={() => {
                setSelectedPermission(Roles.USER);
              }}
            />
            <CustomTypography
              style={{ marginLeft: 40, marginBottom: 16 }}
              className={clsx('text-12')}
              color="grey"
            >
              Имеет доступ в приложение и может просматривать профиль приложения
            </CustomTypography>
          </>
        )}
        <CustomRadioButton
          label="Администратор личного кабинета"
          checked={radioButtonSelected(Roles.ADMIN)}
          onClick={() => {
            setSelectedPermission(Roles.ADMIN);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 32 }}
          className={clsx('text-12')}
          color="grey"
        >
          Может создавать свои приложения, управлять полномочиями участников своих приложений,
          просматривать персональные данные участников своих приложений
        </CustomTypography>
        <CustomRadioButton
          label="Владелец личного кабинета"
          checked={radioButtonSelected(Roles.OWNER)}
          onClick={() => {
            setSelectedPermission(Roles.OWNER);
          }}
        />
        <CustomTypography
          style={{ marginLeft: 40, marginBottom: 32 }}
          className={clsx('text-12')}
          color="grey"
        >
          Имеет полный контроль над всеми пользователями и приложениями
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="custom" color="secondary" onClick={closeChangePermissionModal}>
            Отмена
          </Button>
          <CustomButton
            disabled={disableButtons}
            loading={disableButtons}
            onClick={handleSaveRole}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            Сохранить
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const blockUserModal = () => (
    <Modal open={blockUsersModalOpen} onClose={() => setBlockUsersModalOpen(false)}>
      <div className={styles['modal-wrapper']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {!userToActions?.blocked ? 'Заблокировать ' : 'Разблокировать '}
            пользователя
          </CustomTypography>
          <IconButton
            disabled={disableButtons}
            onClick={() => setBlockUsersModalOpen(false)}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
          {!userToActions?.blocked
            ? `Пользователь потеряет доступ к приложениям, в которых для входа использовал аккаунт 
            ${PROJECT_NAME}. Данные, связанные с аккаунтом, будут сохранены.`
            : `Пользователь будет разблокирован и снова получит доступ к аккаунту ${PROJECT_NAME}.`}
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            variant="custom"
            color="secondary"
            onClick={() => setBlockUsersModalOpen(false)}
          >
            Отмена
          </Button>
          <CustomButton
            loading={disableButtons}
            disabled={disableButtons}
            style={{ marginLeft: 24 }}
            onClick={handleBlockUser}
          >
            {!userToActions?.blocked ? 'Заблокировать' : 'Разблокировать'}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const deleteUserSessionsModal = () => (
    <Modal
      open={deleteUsersSessionsModalOpen}
      onClose={() => setDeleteUsersSessionsModalOpen(false)}
    >
      <div className={styles['modal-wrapper']}>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            Завершить сеансы
          </CustomTypography>
          <IconButton
            disabled={disableButtons}
            onClick={() => setDeleteUsersSessionsModalOpen(false)}
            style={{ marginLeft: 'auto', padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CustomTypography style={{ marginBottom: 40 }} className={clsx('text-14')}>
          Завершить сеансы {userToActions?.nickname} во всех приложениях на всех устройствах?
        </CustomTypography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            variant="custom"
            color="secondary"
            onClick={() => setDeleteUsersSessionsModalOpen(false)}
          >
            Отмена
          </Button>
          <CustomButton
            loading={deleteUsersSessionsLoading}
            disabled={deleteUsersSessionsLoading}
            style={{ marginLeft: 24 }}
            onClick={handleDeleteUsersSessions}
          >
            Завершить
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const deleteUserModal = () => (
    <Modal open={deleteUsersModalOpen} onClose={() => setDeleteUsersModalOpen(false)}>
      <div className={styles['modal-wrapper']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('header-2-medium', 'font-golos')}
        >
          Удалить аккаунт {PROJECT_NAME}
        </CustomTypography>
        <div>
          <CustomTypography className={clsx('text-14')}>
            Аккаунт {userToActions?.nickname || userToActions?.login || userToActions?.id} будет
            удален. Данные, связанные с аккаунтом, удалятся навсегда. Пользователь потеряет доступ к
            приложениям, в которых для входа использовал аккаунт {PROJECT_NAME}.
          </CustomTypography>
          {!!deleteUsersInfoData?.apps.length && (
            <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
              Вместе с аккаунтом будут удалены приложения без участников, в которых удаляемый
              пользователь является единственным администратором.
            </CustomTypography>
          )}
          {!!applicationsToDelete.length && (
            <>
              <CustomTypography style={{ marginTop: 16 }} className={clsx('text-14')}>
                Выберите, что нужно сделать с приложениями с участниками, в которых удаляемый
                пользователь является единственным администратором:
              </CustomTypography>
              <div style={{ marginTop: 10 }}>
                <CustomRadioButton
                  label="Удалить только аккаунты"
                  className={styles['radio-delete-users']}
                  onClick={() => setDeleteAppsWithUser(false)}
                  checked={!deleteAppsWithUser}
                />
                <CustomRadioButton
                  label="Удалить аккаунты и все приложения, которыми они управляют"
                  className={styles['radio-delete-users']}
                  onClick={() => setDeleteAppsWithUser(true)}
                  checked={deleteAppsWithUser}
                />
              </div>
            </>
          )}
        </div>
        {!!applicationsToDelete.length && (
          <div className={styles['apps-to-delete']}>
            {applicationsToDelete.map((app) => (
              <div key={app.client_id} className={styles['app-to-delete']}>
                <div
                  style={{
                    backgroundImage: `url(${BACKEND_URL + '/' + app.avatar})`,
                  }}
                  className={styles['app-icon']}
                >
                  {!app.avatar && <AppIcon fill="#ced0d9" />}
                </div>
                <Link className={styles['app-link']} to={`/applications/${app.client_id}`}>
                  {app.name}
                </Link>
              </div>
            ))}
          </div>
        )}
        <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={disableButtons}
            onClick={() => setDeleteUsersModalOpen(false)}
            variant="custom"
            color="secondary"
          >
            Отмена
          </Button>
          <CustomButton
            loading={disableButtons}
            disabled={disableButtons}
            onClick={handleDeleteUser}
            color="error"
            style={{ marginLeft: 24 }}
          >
            Удалить аккаунт
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const headerRenderer = ({ label, dataKey }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(styles['table-cell'], styles['flex-container'], styles['header-cell'])}
        variant="head"
        align="left"
      >
        {dataKey === 'user' && (
          <CustomCheckbox
            checked={
              !!usersWithoutOwner.length &&
              (allUsersChecked || checkedUsers.length === usersWithoutOwner.length)
            }
            indeterminate={
              (checkedUsers.length !== usersWithoutOwner.length && !!checkedUsers.length) ||
              (uncheckedUsers.length !== usersWithoutOwner.length && !!uncheckedUsers.length)
            }
            onClick={handleCheckAllUsers}
            className={styles.checkbox}
          />
        )}
        <Button
          onClick={() => handleRequestSort(dataKey as TColumnNames)}
          className={styles['header-button']}
        >
          <CustomTypography className={clsx('text-14')} color="grey">
            {label}
          </CustomTypography>
        </Button>
        {dataKey === orderBy && (
          <ArrowTopIcon className={clsx({ [styles['rotate-180']]: !(order === 'asc') })} />
        )}
      </TableCell>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['divider']}>
        <Autocomplete
          value={searchValue}
          onChange={(event, value) => {
            onSearchDebounce(value || '');
            setSearchValue(value || '');
          }}
          freeSolo={true}
          options={
            !!searchValue ||
            getUsersCountFetching ||
            (searchHistoryLoading && !deletingSearchFields.length)
              ? []
              : searchHistory || []
          }
          renderOption={(props, option) => {
            return (
              <li {...props}>
                {deletingSearchFields.includes(option) ? (
                  <CircularProgress />
                ) : (
                  <>
                    <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {option}
                    </span>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteSearchHistory({
                          userId: user.id || '',
                          searchItem: option,
                          type: SearchHistoryTypes.users,
                        });
                        setDeletingSearchFields((fields) => [...fields, option]);
                      }}
                      disabled={deletingSearchFields.includes(option)}
                      className={styles['delete-icon-button']}
                      style={{ marginLeft: 'auto' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )}
              </li>
            );
          }}
          loading={searchHistoryLoading}
          renderInput={(params) => {
            return (
              <>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    className: styles['search-input'],
                    startAdornment: <SearchIcon className={styles['search-icon']} />,
                    endAdornment: !!searchValue && (
                      <IconButton
                        onClick={() => {
                          setSearchHistoryLoading(true);
                          onSearchDebounce('');
                          setSearchValue('');
                        }}
                        disabled={!searchValue}
                        className={styles['delete-icon-button']}
                      >
                        <CloseIcon />
                      </IconButton>
                    ),
                  }}
                  placeholder="Поиск"
                  variant="standard"
                  onChange={(e) => {
                    onSearchDebounce(e.target.value);
                    setSearchValue(e.target.value);
                  }}
                />
              </>
            );
          }}
        />
        <div className={styles['action-section']}>
          <Tooltip
            arrow
            open={searchFieldTooltipOpen && !selectOpen}
            title={selectedSearchFields
              .map(
                (value) => searchFieldTypeVariants.find((variant) => variant.value === value)?.name,
              )
              .join(', ')}
            classes={{
              tooltip: styles['input-tooltip'],
              arrow: styles['input-tooltip-arrow'],
            }}
          >
            <CustomSelect
              open={selectOpen}
              onClick={() => {
                if (!selectOpen) setSelectedSearchFieldsBeforeChange(selectedSearchFields);
                setSelectOpen(!selectOpen);
              }}
              onClose={() => {
                setSearchFieldsTooltipOpen(false);
                setSelectOpen(false);
              }}
              onMouseOver={(e) => {
                if (e.target === e.currentTarget) setSearchFieldsTooltipOpen(true);
              }}
              onMouseLeave={() => setSearchFieldsTooltipOpen(false)}
              IconComponent={() => (
                <>
                  {!!selectedSearchFields.length && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (searchValue) {
                          setSearchHistoryLoading(true);
                          onSearchDebounce(searchValue);
                        }
                        setSelectedSearchFields([]);
                      }}
                      className={styles['delete-icon-button']}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <ArrowDropDownIcon
                    style={{
                      width: 40,
                      transform: selectOpen ? 'rotate(180deg)' : '',
                      cursor: 'pointer',
                    }}
                  />
                </>
              )}
              multiple
              className={styles.select}
              value={selectedSearchFields}
              displayEmpty={true}
              renderValue={(values) => (
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {values.length
                    ? values
                        .map(
                          (value) =>
                            searchFieldTypeVariants.find((variant) => variant.value === value)
                              ?.name,
                        )
                        .join(', ')
                    : 'Все'}
                </div>
              )}
              onChange={(e) => {
                const targetValue = e.target.value as string[];
                setSelectedSearchFields(targetValue);
              }}
            >
              {searchFieldTypeVariants.map((variant) => (
                <MenuItem
                  className="custom-select"
                  key={variant.value}
                  value={variant.value}
                  onClick={(e) => e.stopPropagation()}
                >
                  {variant.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </Tooltip>
          <Popover
            classes={{ paper: styles['view-popover-paper'] }}
            onClose={() => setViewAnchorEl(null)}
            anchorEl={viewAnchorEl}
            open={!!viewAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
          </Popover>
          <Tooltip placement="bottom-end" arrow title="Создать пользователя">
            <Button
              onClick={() => navigate('create')}
              variant="custom"
              color="secondary"
              className={styles['create-user-button']}
              startIcon={<PersonAddAltOutlinedIcon />}
            />
          </Tooltip>
          {!isMobile && (
            <Button
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                setViewAnchorEl(event.currentTarget)
              }
              className={styles.view}
              classes={{ endIcon: styles['view-icon'] }}
              endIcon={<ArrowDown fill="#9DA2B3" />}
            >
              <CustomTypography className={clsx('text-14')} color="grey">
                Вид
              </CustomTypography>
            </Button>
          )}
        </div>
      </div>
      <div
        ref={loaderWrapperRef}
        className={clsx(styles['table-wrapper'], {
          [styles['animate-width']]: animateWidth,
        })}
      >
        <InfiniteLoader
          ref={infiniteLoaderRef}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={rowCount}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <AutoSizer>
                {({ height }) => {
                  return (
                    <>
                      {!!usersWithoutOwner.length &&
                        (!!checkedUsers.length ||
                          allUsersChecked ||
                          (!!uncheckedUsers.length &&
                            uncheckedUsers.length !== usersWithoutOwner.length)) && (
                          <UsersControlMultipleOperations
                            search_filter={
                              !selectedSearchFields.length || !searchValue
                                ? ['all']
                                : selectedSearchFields
                            }
                            checkedActiveUsersCount={
                              checkedUsers.length
                                ? checkedUsers.reduce(
                                    (acc, { user }) => acc + (!user.blocked ? 1 : 0),
                                    0,
                                  )
                                : (activeUsersCount?.active_users_count || 0) -
                                  uncheckedUsers.reduce((acc, { user }) => {
                                    return acc + (user.blocked ? 0 : 1);
                                  }, 0)
                            }
                            checkedUsers={checkedUsers}
                            clearUsers={clearUsers}
                            refetchUsersCount={refetchUsersCount}
                            getActiveUsersCountLoading={getActiveUsersCountLoading}
                            handleCheckAllUsers={handleCheckAllUsers}
                            onSearch={onSearch}
                            searchValue={searchValue}
                            setAllUsersChecked={handleCheckAllUsers}
                            uncheckedUsers={uncheckedUsers}
                            setCheckedUsers={setCheckedUsers}
                            users={usersWithoutOwner}
                            allCheckedUsersLength={activeUsersCount?.users_count || 0}
                            width={width}
                            allUsersChecked={allUsersChecked}
                            ownerId={parseInt(owner.id || '0', 10)}
                            dataSettings={dataSettings}
                          />
                        )}
                      <Table
                        headerClassName={styles.header}
                        onRowClick={onRowClick}
                        gridClassName={'users-table__grid'}
                        id="table"
                        height={height}
                        width={width}
                        rowHeight={80}
                        headerHeight={56}
                        rowCount={rowCount}
                        rowGetter={rowGetter}
                        rowClassName={getRowClassName}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onScroll={(e) => {
                          saveParamsRef.current.scrollTop = e.scrollTop;
                        }}
                        onRowDoubleClick={() => {
                          navigate(`/applications/user/${CLIENT_ID}/${selectedUser?.user.id}`);
                        }}
                      >
                        <Column
                          className={styles.column}
                          label="Имя"
                          width={columnWidth}
                          key="name"
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 0,
                            })
                          }
                          cellRenderer={({ cellData, rowData }) => {
                            const avatar = getImageURL(cellData?.picture);
                            const userId = String(cellData?.id);
                            return (
                              <TableCell
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                component="div"
                                variant="body"
                                align="left"
                              >
                                <div>
                                  {isObjectEmpty(rowData) || getUsersCountFetching ? (
                                    <Skeleton width={50} />
                                  ) : (
                                    <div className={styles['flex-container']}>
                                      <CustomCheckbox
                                        disabled={userId === owner.id || userId === '1'}
                                        className={styles.checkbox}
                                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                          e.stopPropagation();
                                          handleCheckUser(rowData);
                                        }}
                                        checked={
                                          userId !== owner.id &&
                                          userId !== '1' &&
                                          userChecked(rowData)
                                        }
                                      />
                                      <div
                                        className={styles['app-icon-wrapper']}
                                        style={{
                                          backgroundImage: avatar ? `url(${avatar})` : undefined,
                                        }}
                                      >
                                        {!cellData?.picture && (
                                          <div className={styles['app-icon-default']}>
                                            {cellData?.nickname
                                              ?.split(' ')
                                              .map((name: string) => name[0]?.toUpperCase())
                                              .join('')}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          maxWidth: width / columnsCount - 130 - 100 / columnsCount,
                                          transition: '0.5s ease max-width',
                                        }}
                                      >
                                        <CustomTypography variant="link">
                                          <Link
                                            style={{
                                              display: 'block',
                                              width: '100%',
                                              textDecoration: 'none',
                                              color: 'inherit',
                                            }}
                                            className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                            to={
                                              Number(owner?.id) === rowData?.user?.id
                                                ? '/profile'
                                                : `/applications/user/${CLIENT_ID}/${rowData?.user?.id}`
                                            }
                                          >
                                            {(rowData?.user.nickname || '').trim()
                                              ? rowData?.user.nickname
                                              : (
                                                  (rowData?.user.given_name || '') +
                                                  ' ' +
                                                  (rowData?.user.family_name || '')
                                                ).trim() || 'Нет имени'}
                                          </Link>
                                        </CustomTypography>
                                        <CustomTypography
                                          style={{
                                            maxWidth:
                                              width / columnsCount - 70 - 100 / columnsCount,
                                          }}
                                          className={clsx(styles['overflow-ellipsis'], 'text-12')}
                                          color="grey"
                                        >
                                          ID {cellData?.id}
                                        </CustomTypography>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            );
                          }}
                          dataKey="user"
                        />
                        <Column
                          className={styles.column}
                          label="Права"
                          dataKey="role"
                          width={columnsCount > 1 ? columnWidth : 0}
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 1,
                            })
                          }
                          cellRenderer={({ cellData, rowData }) => {
                            return (
                              <TableCell
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                component="div"
                                variant="body"
                                align="left"
                              >
                                <CustomTypography
                                  style={{ maxWidth: width * 0.5 - 40 }}
                                  className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                >
                                  {isObjectEmpty(rowData) || getUsersCountFetching ? (
                                    <Skeleton width={50} />
                                  ) : (
                                    getRoleName(cellData)
                                  )}
                                </CustomTypography>
                              </TableCell>
                            );
                          }}
                        />
                        <Column
                          className={styles.column}
                          label="Статус"
                          dataKey="status"
                          width={columnsCount > 2 ? columnWidth : 0}
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 2,
                            })
                          }
                          cellRenderer={({ rowData }) => {
                            return (
                              <TableCell
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                component="div"
                                variant="body"
                                align="left"
                              >
                                {isObjectEmpty(rowData) || getUsersCountFetching ? (
                                  <Skeleton width={50} />
                                ) : (
                                  <div
                                    style={{ maxWidth: width * 0.5 - 40 }}
                                    className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                  >
                                    {getUserStatus(rowData?.user)}
                                  </div>
                                )}
                              </TableCell>
                            );
                          }}
                        />
                        <Column
                          dataKey="key"
                          className={styles['actions-button-column']}
                          cellRenderer={({ rowData }) => (
                            <TableCell
                              component="div"
                              className={clsx(styles['table-cell'], styles['flex-container'])}
                              variant="head"
                              align="right"
                            >
                              <Popover
                                classes={{
                                  paper: clsx(styles.paper, styles['popover-paper'], {
                                    [styles['horizontal-direction-top']]: popoverOriginTop,
                                  }),
                                }}
                                onClose={(e: MouseEvent<ReactElement>) => {
                                  e.stopPropagation();
                                  handleClosePopover();
                                }}
                                anchorEl={anchorEl}
                                open={isOpen && rowData?.user?.id === userToActions?.id}
                                anchorOrigin={{
                                  vertical: popoverOriginTop ? 'top' : 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: popoverOriginTop ? 'bottom' : 'top',
                                  horizontal: 'right',
                                }}
                              >
                                {!userToActions?.deleted && (
                                  <CustomPopoverButton
                                    className={styles['custom-popover-button']}
                                    onClick={() => {
                                      if (selectedUserToActions)
                                        exportToJson(selectedUserToActions, 'profile.json');
                                    }}
                                    startIcon={
                                      <ExportIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Скачать данные профиля
                                  </CustomPopoverButton>
                                )}
                                <Link
                                  className={styles['custom-popover-button']}
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textDecoration: 'none',
                                  }}
                                  to={
                                    owner?.id === String(userToActions?.id)
                                      ? '/profile'
                                      : `/applications/user/${CLIENT_ID}/${rowData?.user?.id}`
                                  }
                                >
                                  <CustomPopoverButton
                                    startIcon={
                                      <AvatarIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Посмотреть профиль
                                  </CustomPopoverButton>
                                </Link>
                                {String(userToActions?.id) !== owner.id && userToActions?.id !== 1 && (
                                  <CustomPopoverButton
                                    className={styles['custom-popover-button']}
                                    onClick={() => {
                                      setChangePermissionsModalOpen(true);
                                    }}
                                    startIcon={
                                      <OwnerIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Изменить права
                                  </CustomPopoverButton>
                                )}
                                {!userToActions?.deleted && !userToActions?.blocked && (
                                  <CustomPopoverButton
                                    className={styles['custom-popover-button']}
                                    onClick={() => setDeleteUsersSessionsModalOpen(true)}
                                    startIcon={
                                      <QuitIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Завершить все сеансы
                                  </CustomPopoverButton>
                                )}
                                {String(userToActions?.id) !== owner.id && userToActions?.id !== 1 && (
                                  <CustomPopoverButton
                                    className={styles['custom-popover-button']}
                                    onClick={() => setBlockUsersModalOpen(true)}
                                    startIcon={
                                      userToActions?.blocked ? (
                                        <UnlockIcon className={styles['action-button-icon']} />
                                      ) : (
                                        <LockIcon className={styles['action-button-icon']} />
                                      )
                                    }
                                  >
                                    {userToActions?.blocked ? 'Разблокировать' : 'Заблокировать'} в{' '}
                                    {PROJECT_NAME}
                                  </CustomPopoverButton>
                                )}
                                {String(userToActions?.id) !== owner.id && userToActions?.id !== 1 && (
                                  <CustomPopoverButton
                                    className={styles['custom-popover-button']}
                                    onClick={handleDeleteButton}
                                    startIcon={
                                      <BasketIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Удалить аккаунт
                                  </CustomPopoverButton>
                                )}
                              </Popover>
                              <IconButton
                                disabled={actionPopoverDisabled}
                                onClick={(event) => handleOpenPopover(event, rowData)}
                                className={clsx(styles['open-actions-icon'], {
                                  [styles['active-actions-icon']]:
                                    rowData?.user?.id === userToActions?.id && isOpen,
                                })}
                              >
                                <ActionsIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          width={lastColumnWidth}
                        />
                      </Table>
                    </>
                  );
                }}
              </AutoSizer>
            );
          }}
        </InfiniteLoader>
        <UsersRightPanel userId={selectedUser?.user.id} updateUserList={handleUpdateUsersList} />
      </div>
      {changePermissionsModal()}
      {deleteUserSessionsModal()}
      {deleteUserModal()}
      {blockUserModal()}
    </div>
  );
};

export const UsersControl = connect(mapStateToProps)(UsersControlComponent);
