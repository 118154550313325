import React, {
  FC,
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import Box from '@mui/material/Box';
import styles from './ApplicationsTable.module.css';
import clsx from 'clsx';
import { formatDate, isOwner } from '../../helpers';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setApplicationInfoTab, setSelectedClientId, TAppSlice } from '../../redux/appSlice';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as WithdrawIcon } from '../../icons/Withdraw.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { debounce } from '@mui/material/utils';
import { ReactComponent as EditIcon } from '../../icons/Edit.svg';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as RemoveIcon } from '../../icons/Close.svg';
import { ReactComponent as EmptySearchIcon } from '../../icons/EmptySearch.svg';
import {
  useLazyGetClientsByScopesQuery,
  useLazyGetApplicationsCountByScopesQuery,
  useLazyGetApplicationsCountQuery,
  useLazyGetApplicationsQuery,
} from '../../redux/services/client';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import {
  useRevokeScopesMutation,
  useLazyDeleteSessionByClientQuery,
  useDeleteApplicationMutation,
  TApplication,
} from '../../redux/services/client';
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  RowMouseEventHandlerParams,
  Table,
  TableHeaderProps,
} from 'react-virtualized';
import { TUserProfile } from '../../redux/userSlice';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import Skeleton from '@mui/material/Skeleton';
import {
  useLazyGetAllApplicationsCountQuery,
  useLazyGetAllApplicationsQuery,
} from '../../redux/services/owner';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import { useNavigate, Link as RouterLink } from 'react-router-dom-v5-compat';
import { ModalDelete } from '../modal/ModalDelete';
import { CustomTypography } from '../custom/CustomTypography';

export type Order = 'asc' | 'desc';
export type TColumnNames = 'name' | 'domain' | 'created_at';

export type TApplicationsTableProps = {
  userId?: string;
  userRole?: string;
  variant?: string;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  selectedClientId: TAppSlice['selectedClientId'];
  role: TUserProfile['role'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  isMobile: TAppSlice['isMobile'];
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
  isClientPanelOpen: state.app.isClientPanelOpen,
  selectedClientId: state.app.selectedClientId,
  role: state.user.userProfile.role,
  clientPanelWidth: state.app.clientPanelWidth,
  isMobile: state.app.isMobile,
});

const ApplicationsTableComponent: FC<TApplicationsTableProps> = ({
  userId,
  userRole,
  variant,
  isClientPanelOpen,
  selectedClientId,
  role,
  clientPanelWidth,
  isMobile,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getApplications] = useLazyGetApplicationsQuery();
  const [getApplicationsCount, { isLoading: getApplicationsCountLoading }] =
    useLazyGetApplicationsCountQuery();
  const [getAllApplicationsCount, { isLoading: getAllApplicationsCountLoading }] =
    useLazyGetAllApplicationsCountQuery();
  const [getApplicationsByScopes, { isFetching: getApplicationsByScopesFetching }] =
    useLazyGetClientsByScopesQuery();
  const [getApplicationCountByScopes, { isLoading: getApplicationCountByScopesLoading }] =
    useLazyGetApplicationsCountByScopesQuery();
  const [getAllApplications, { isFetching: getAllApplicationsFetching }] =
    useLazyGetAllApplicationsQuery();
  const [animateWidth, setAnimateWidth] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [clientsCountLoadingStarted, setClientsCountLoadingStarted] = useState(true);
  const [applications, setApplications] = useState<(TApplication | undefined)[]>([]);
  const [orderBy, setOrderBy] = useState<TColumnNames>('name');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElScopes, setAnchorElScopes] = useState<HTMLButtonElement | null>(null);
  const mounted = useRef(false);
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [popoverOriginTopScopes, setPopoverOriginTopScopes] = useState(false);
  const [width, setWidth] = useState(0);
  const [activeActionButton, setActiveActionButton] = useState<string>('');
  const [activeActionButtonScopes, setActiveActionButtonScopes] = useState<string>('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isOpen = Boolean(anchorEl);
  const isOpenScopes = Boolean(anchorElScopes);
  const sortedApplications = applications?.map((app) => ({
    name: app?.client.name,
    avatar: app?.client.avatar,
    domain: app?.client.domain,
    created_at: app?.client.created_at,
    role: app?.role,
    id: app?.client.client_id,
  }));
  const firstElementId = sortedApplications[0]?.id;
  const rowCount = applications.length;
  const columnsCount = Math.min(Math.round(width / 350), 3);
  const columnWidth = width / columnsCount - 80 / columnsCount;
  const [deleteApplication] = useDeleteApplicationMutation();
  const [revokeScopes] = useRevokeScopesMutation();
  const [deleteSessionByClient] = useLazyDeleteSessionByClientQuery();
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const isRowLoaded = ({ index }: { index: number }) => !!applications[index];
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const loadMoreRowsRef = useRef<null | (() => Promise<void>)>(null);

  useEffect(() => {
    setApplications((applications) => applications.map(() => undefined));
  }, [order, orderBy]);

  useEffect(() => {
    if (!getApplicationsByScopesFetching && !getAllApplicationsFetching) {
      loadMoreRowsRef.current?.();
      loadMoreRowsRef.current = null;
    }
  }, [getApplicationsByScopesFetching, getAllApplicationsFetching]);

  useEffect(() => {
    if (!selectedClientId && firstElementId) {
      dispatch(setSelectedClientId(firstElementId));
    }

    if (selectedClientId && applications[rowCount - 1] !== undefined) {
      const isUserOnList = applications.some((app) => app?.client.client_id === selectedClientId);
      if (!isUserOnList) {
        dispatch(setSelectedClientId(firstElementId));
      }
    }
  }, [variant, applications, firstElementId]);

  useEffect(() => {
    if (selectedClientId === undefined) {
      reloadApplications();
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (applications.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [applications]);

  useEffect(() => {
    setSearchValue('');
    if (userId) reloadApplications();
    return () => setApplications([]);
  }, [userRole, userId, variant]);

  useEffect(() => {
    if (!mounted.current) {
      setAnimateWidth(false);
      mounted.current = true;
    } else {
      setAnimateWidth(true);
      return () => setAnimateWidth(false);
    }
  }, [isClientPanelOpen]);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth - 31 - +!isMobile * (clientPanelWidth * +!isClientPanelOpen));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isClientPanelOpen, clientPanelWidth, isMobile]);

  const reloadApplications = async () => {
    if (!userId) return;
    setClientsCountLoadingStarted(true);

    let appCount: number | undefined;
    if (variant === 'admin') {
      if (isOwner(userRole)) {
        const { data } = await getAllApplicationsCount({
          userId,
        });

        appCount = data;
      } else {
        const { data } = await getApplicationsCount({
          userId,
        });

        appCount = data;
      }
    } else {
      const { data } = await getApplicationCountByScopes({
        userId,
      });

      appCount = data;
    }
    setClientsCountLoadingStarted(false);
    if (appCount !== undefined) setApplications(new Array(appCount).fill(undefined));
  };
  const sortingApplications = (
    data: TApplication[] | undefined,
    startIndex: number,
    stopIndex: number,
  ) => {
    setApplications((applications) =>
      applications.map((app, index) => {
        if (index < startIndex || index > stopIndex) return app;
        return data?.[index - startIndex];
      }),
    );
  };

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
    ignoreAppsLoading,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
    ignoreAppsLoading?: boolean;
  }) => {
    if (!userId) return;

    if ((!getAllApplicationsFetching && !getApplicationsByScopesFetching) || ignoreAppsLoading) {
      const queryParams = {
        user_id: userId || '',
        search_string: searchString === undefined ? searchValue : searchString,
        sort_by: orderBy,
        sort_direction: order,
        number_of_records: String(stopIndex - startIndex + 1),
        number_of_skip: String(startIndex),
      };
      if (variant === 'admin') {
        if (isOwner(userRole)) {
          const { data } = await getAllApplications(queryParams);
          sortingApplications(data, startIndex, stopIndex);
        } else {
          const { data } = await getApplications(queryParams);
          sortingApplications(data, startIndex, stopIndex);
        }
      } else {
        const { data } = await getApplicationsByScopes(queryParams);
        sortingApplications(data, startIndex, stopIndex);
      }
    } else {
      loadMoreRowsRef.current = () =>
        loadMoreRows({ startIndex, stopIndex, searchString, ignoreAppsLoading: true });
    }
  };

  const onSearch = async (searchString: string) => {
    let appCount: number | undefined;
    setClientsCountLoadingStarted(true);
    if (variant === 'admin') {
      if (isOwner(userRole)) {
        const { data } = await getAllApplicationsCount({
          userId: userId || '',
          searchString,
        });

        appCount = data;
      } else {
        const { data } = await getApplicationsCount({
          userId: userId || '',
          searchString,
        });

        appCount = data;
      }
    } else {
      const { data } = await getApplicationCountByScopes({
        userId: userId || '',
        searchString,
      });

      appCount = data;
    }
    setClientsCountLoadingStarted(false);
    if (appCount !== undefined) gridApplications(appCount);
  };

  const gridApplications = (appCount: number) => {
    setApplications(new Array(appCount).fill(undefined));
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 200), [selectedClientId, order, orderBy]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const rowGetter = ({ index }: { index: number }) => {
    return sortedApplications?.[index] || {};
  };

  const handleRevokeScopes = async (userId: string, clientId: string) => {
    await revokeScopes({ userId, clientId });
    setApplications((applications) =>
      applications.filter((application) => application?.client.client_id !== clientId),
    );
  };

  const handleDeleteSessionByClient = async (userId: string, clientId: string) => {
    await deleteSessionByClient({ userId: userId, clientId: clientId });
    window.location.reload();
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    if (event.clientY + 260 > document.body.clientHeight) setPopoverOriginTop(true);
    else setPopoverOriginTop(false);
    setActiveActionButton(id);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenPopoverScopes = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    if (event.clientY + 260 > document.body.clientHeight) setPopoverOriginTopScopes(true);
    else setPopoverOriginTopScopes(false);
    setActiveActionButtonScopes(id);
    setAnchorElScopes(event.currentTarget);
  };

  const handleClosePopover = (event: MouseEvent<ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setActiveActionButton('');
  };

  const handleClosePopoverScopes = (event: MouseEvent<ReactElement>) => {
    event.stopPropagation();
    setAnchorElScopes(null);
    setActiveActionButtonScopes('');
  };

  const onRowClick = (rowInfo: RowMouseEventHandlerParams) => {
    dispatch(setSelectedClientId(rowInfo.rowData.id));
  };

  const handleDeleteApp = async (clientId: string) => {
    setAnchorEl(null);
    if (activeActionButton === selectedClientId) setSelectedClientId(undefined);
    setActiveActionButton('');

    await deleteApplication(clientId);
    setApplications((applications) =>
      applications.filter((application) => application?.client.client_id !== clientId),
    );
    setDeleteModalOpen(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    return clsx(styles['table-row'], styles['flex-container'], {
      [styles['content-row']]: index !== -1,
      [styles['selected-row']]:
        selectedClientId && selectedClientId === rowGetter?.({ index })?.id && index !== -1,
    });
  };

  const handleRequestSort = (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const headerRenderer = ({ label, dataKey }: TableHeaderProps & { columnIndex: number }) => {
    return (
      <TableCell
        component="div"
        className={clsx(styles['table-cell'], styles['flex-container'], styles['header-cell'])}
        variant="head"
        align="left"
        onClick={() => handleRequestSort(dataKey as TColumnNames)}
      >
        <Button className={styles['header-button']}>
          <CustomTypography className={clsx('text-14')} color="grey">
            {label}
          </CustomTypography>
        </Button>
        {dataKey === orderBy && (
          <ArrowTopIcon className={clsx({ [styles['rotate-180']]: !(order === 'asc') })} />
        )}
      </TableCell>
    );
  };

  return (
    <Box
      className={clsx(styles['applications-table'], {
        [styles['full-width']]: isClientPanelOpen || isMobile,
        [styles['applications-table-admin']]: variant === 'admin',
      })}
    >
      <div
        className={clsx(styles.wrapper, {
          [styles['wrapper-panel-open']]: isClientPanelOpen,
          [styles['animate-width']]: animateWidth,
        })}
      >
        <InfiniteLoader
          ref={infiniteLoaderRef}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={rowCount}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <AutoSizer>
                {({ height }) => {
                  return (
                    <>
                      {!applications.length &&
                        !clientsCountLoadingStarted &&
                        (variant === 'admin'
                          ? isOwner(role)
                            ? !getAllApplicationsCountLoading
                            : !getApplicationsCountLoading
                          : !getApplicationCountByScopesLoading) && <EmptyApplicationsTable />}
                      <Table
                        headerClassName={styles['header-column']}
                        onRowClick={onRowClick}
                        gridClassName={'applications-table__grid'}
                        height={height}
                        width={width}
                        rowHeight={80}
                        headerHeight={56}
                        rowCount={rowCount}
                        rowGetter={rowGetter}
                        rowClassName={getRowClassName}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        onRowDoubleClick={() => {
                          navigate(`/applications/edit/${selectedClientId}`);
                        }}
                      >
                        <Column
                          label="Имя"
                          width={columnWidth}
                          key="name"
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 0,
                            })
                          }
                          className={styles['flex-container']}
                          cellRenderer={({ cellData, rowData }) => {
                            return (
                              <TableCell
                                component="div"
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                variant="body"
                                align="left"
                              >
                                <Box
                                  style={{
                                    backgroundImage:
                                      rowData.avatar &&
                                      `url(${BACKEND_URL + '/' + rowData.avatar})`,
                                  }}
                                  className={styles['app-icon']}
                                >
                                  {!rowData.avatar &&
                                    (!rowData.id ? (
                                      <Skeleton width="50%" />
                                    ) : (
                                      <AppIcon fill="#ced0d9" />
                                    ))}
                                </Box>
                                {!rowData.id ? (
                                  <Skeleton width="50%" />
                                ) : (
                                  <RouterLink
                                    className={styles['app-link']}
                                    to={`${rowData.id}`}
                                    onClick={() => dispatch(setApplicationInfoTab(0))}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <CustomTypography
                                      variant="link"
                                      style={{
                                        width: 'auto',
                                        maxWidth: columnWidth - 90,
                                        transition: '0.5s ease all',
                                      }}
                                      className={clsx(
                                        styles['overflow-ellipsis'],
                                        'text-14',
                                        styles.pointer,
                                      )}
                                    >
                                      {cellData}
                                    </CustomTypography>
                                  </RouterLink>
                                )}
                              </TableCell>
                            );
                          }}
                          dataKey="name"
                        />
                        <Column
                          label="Адрес приложения"
                          width={columnsCount > 1 ? columnWidth : 0}
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 1,
                            })
                          }
                          className={styles['flex-container']}
                          cellRenderer={({ cellData, rowData }) => {
                            return (
                              <TableCell
                                component="div"
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                variant="body"
                                align="left"
                              >
                                {!rowData.id ? (
                                  <Skeleton width="50%" />
                                ) : (
                                  <Link
                                    underline="hover"
                                    href={cellData || ''}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <CustomTypography
                                      variant="link"
                                      style={{
                                        maxWidth: columnWidth - 30,
                                        transition: '0.5s ease all',
                                      }}
                                      className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                    >
                                      {cellData}
                                    </CustomTypography>
                                  </Link>
                                )}
                              </TableCell>
                            );
                          }}
                          dataKey="domain"
                        />
                        <Column
                          label="Доступ предоставлен"
                          width={columnsCount > 2 ? columnWidth : 0}
                          headerRenderer={(headerProps) =>
                            headerRenderer({
                              ...headerProps,
                              columnIndex: 2,
                            })
                          }
                          className={styles['flex-container']}
                          cellRenderer={({ cellData, rowData }) => {
                            const createdAt = new Date(cellData);
                            return (
                              <TableCell
                                component="div"
                                className={clsx(styles['table-cell'], styles['flex-container'])}
                                variant="body"
                                align="left"
                              >
                                {!rowData.id ? (
                                  <Skeleton width="50%" />
                                ) : (
                                  <CustomTypography
                                    style={{ maxWidth: width * 0.22 }}
                                    className={clsx(styles['overflow-ellipsis'], 'text-14')}
                                  >
                                    {formatDate(createdAt)}
                                    {', '}
                                    {`0${createdAt.getHours()}`.slice(-2)}:
                                    {`0${createdAt.getMinutes()}`.slice(-2)}
                                  </CustomTypography>
                                )}
                              </TableCell>
                            );
                          }}
                          dataKey="created_at"
                        />
                        <Column
                          headerRenderer={() => {
                            return (
                              <>
                                {isSearchInputOpen ? (
                                  <ClickAwayListener
                                    onClickAway={() => setIsSearchInputOpen(false)}
                                  >
                                    <div className={styles['search-wrapper']}>
                                      <TextField
                                        inputProps={{ className: styles.input }}
                                        InputProps={{
                                          className: styles['input-root'],
                                        }}
                                        variant="standard"
                                        value={searchValue}
                                        fullWidth
                                        onChange={(e) => {
                                          onSearchDebounce(e.target.value);
                                          setSearchValue(e.target.value);
                                        }}
                                      />
                                      <SearchIcon className={styles['search-icon']} />
                                      <IconButton
                                        onClick={() => {
                                          onSearch('');
                                          setSearchValue('');
                                          setIsSearchInputOpen(false);
                                        }}
                                        className={styles['delete-icon-button']}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </div>
                                  </ClickAwayListener>
                                ) : (
                                  <IconButton
                                    onClick={() => setIsSearchInputOpen(true)}
                                    className={styles['search-icon-button']}
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                )}
                              </>
                            );
                          }}
                          dataKey="key"
                          cellRenderer={({ rowData }) => (
                            <TableCell
                              component="div"
                              className={clsx(styles['table-cell'], styles['flex-container'])}
                              variant="head"
                              align="right"
                            >
                              <Popover
                                classes={{
                                  paper: clsx(styles.paper, styles['popover-paper'], {
                                    [styles['horizontal-direction-top']]: popoverOriginTop,
                                  }),
                                }}
                                onClose={handleClosePopover}
                                anchorEl={anchorEl}
                                open={isOpen && rowData.id === activeActionButton}
                                anchorOrigin={{
                                  vertical: popoverOriginTop ? 'top' : 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: popoverOriginTop ? 'bottom' : 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <CustomPopoverButton
                                  startIcon={<QuitIcon className={styles['action-button-icon']} />}
                                  onClick={() => {
                                    if (userId) {
                                      handleDeleteSessionByClient(userId, rowData.id);
                                    }
                                  }}
                                >
                                  Завершить свои сеансы
                                </CustomPopoverButton>
                                <RouterLink
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textDecoration: 'none',
                                  }}
                                  to={`${rowData?.id}`}
                                >
                                  <CustomPopoverButton
                                    startIcon={
                                      <GroupIcon className={styles['action-button-icon']} />
                                    }
                                    style={{ marginTop: 8 }}
                                    onClick={() => dispatch(setApplicationInfoTab(1))}
                                  >
                                    Участники приложения
                                  </CustomPopoverButton>
                                </RouterLink>
                                <Box className={styles.divider} />
                                <RouterLink
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textDecoration: 'none',
                                  }}
                                  to={`edit/${rowData?.id}`}
                                >
                                  <CustomPopoverButton
                                    startIcon={
                                      <EditIcon className={styles['action-button-icon']} />
                                    }
                                  >
                                    Настройки
                                  </CustomPopoverButton>
                                </RouterLink>
                                {CLIENT_ID !== rowData.id && (
                                  <>
                                    <Box className={styles.divider} />
                                    <Button
                                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        setDeleteModalOpen(true);
                                      }}
                                      className={clsx(styles['action-button'], 'text-14')}
                                      startIcon={
                                        <BasketIcon className={styles['action-button-icon']} />
                                      }
                                      style={{ marginTop: 8 }}
                                    >
                                      Удалить
                                    </Button>
                                  </>
                                )}
                              </Popover>
                              <Popover
                                classes={{
                                  paper: clsx(styles.paper, styles['popover-paper'], {
                                    [styles['horizontal-direction-top']]: popoverOriginTopScopes,
                                  }),
                                }}
                                onClose={handleClosePopoverScopes}
                                anchorEl={anchorElScopes}
                                open={isOpenScopes && rowData.id === activeActionButtonScopes}
                                anchorOrigin={{
                                  vertical: popoverOriginTopScopes ? 'top' : 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: popoverOriginTopScopes ? 'bottom' : 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <CustomPopoverButton
                                  startIcon={<QuitIcon className={styles['action-button-icon']} />}
                                  onClick={() => {
                                    if (userId) {
                                      handleDeleteSessionByClient(userId, rowData.id);
                                    }
                                  }}
                                >
                                  Завершить свои сеансы
                                </CustomPopoverButton>
                                <RouterLink
                                  style={{
                                    display: 'block',
                                    width: '100%',
                                    textDecoration: 'none',
                                  }}
                                  to={`${selectedClientId}`}
                                ></RouterLink>
                                {CLIENT_ID !== rowData.id && (
                                  <>
                                    <Box className={styles.divider} />
                                    <CustomPopoverButton
                                      startIcon={
                                        <WithdrawIcon className={styles['action-button-icon']} />
                                      }
                                      style={{ marginTop: 8 }}
                                      onClick={() => {
                                        if (userId) {
                                          handleRevokeScopes(userId, rowData.id);
                                        }
                                      }}
                                    >
                                      Отозвать разрешения
                                    </CustomPopoverButton>
                                  </>
                                )}
                              </Popover>
                              <IconButton
                                onClick={(event) =>
                                  variant === 'admin'
                                    ? handleOpenPopover(event, rowData.id)
                                    : handleOpenPopoverScopes(event, rowData.id)
                                }
                                className={clsx(styles['open-actions-icon'], {
                                  [styles['active-actions-icon']]:
                                    variant === 'admin'
                                      ? rowData.id === activeActionButton
                                      : rowData.id === activeActionButtonScopes,
                                })}
                              >
                                <ActionsIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          width={40}
                        />
                      </Table>
                    </>
                  );
                }}
              </AutoSizer>
            );
          }}
        </InfiniteLoader>
      </div>

      <ModalDelete
        title="Удалить приложение"
        mainMessage={[
          `Приложение ${sortedApplications.find((app) => app.id === activeActionButton)?.name} будет
            удалено.`,
          `Пользователи не смогут войти в ваше приложение, используя аккаунт ${PROJECT_NAME}.`,
        ]}
        additionMessage="Это действие невозможно отменить."
        isOpen={deleteModalOpen}
        onAction={() => handleDeleteApp(activeActionButton)}
        onClose={closeDeleteModal}
      />
    </Box>
  );
};

const EmptyApplicationsTable = () => (
  <Box className={styles['applications-table-empty']}>
    <EmptySearchIcon className={styles['search-icon']} />
    <CustomTypography
      style={{ textAlign: 'center' }}
      className={clsx('text-17-regular', 'font-golos')}
      color="grey"
    >
      Приложения отсутствуют
    </CustomTypography>
  </Box>
);

export const ApplicationsTable = connect(mapStateToProps)(ApplicationsTableComponent);
