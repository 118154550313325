import React, { useState, FC } from 'react';
import clsx from 'clsx';
import styles from './BindAccount.module.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useBindAccountMutation } from '../../redux/services/user';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { CustomTypography } from '../custom/CustomTypography';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EyeIcon } from '../../icons/Eye.svg';
import { ReactComponent as EyeOffIcon } from '../../icons/EyeOff.svg';

export const Bind1CAccount: FC = () => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [bindError, setBindError] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { provider_id = '' } = useParams<{ provider_id: string }>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [bind1CAccount, { isLoading: bind1CAccountLoading }] = useBindAccountMutation();

  const handleAdd1CAccount = async () => {
    try {
      setUsernameError('');
      if (!username) return setUsernameError('Введите логин');

      if (userId) {
        const bind1CAccountResponse = await bind1CAccount({
          userId,
          provider_id,
          username,
          password,
          rebind: !!bindError,
        }).unwrap();

        if (!bind1CAccountResponse.success)
          return setBindError(`Аккаунт 1C уже привязан к ${bind1CAccountResponse.nickname}`);

        navigate('/profile');
      }
    } catch (e) {
      console.log('handleAdd1CAccount error:', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['change-email']}>
        <CustomTypography
          style={{ marginBottom: 24 }}
          className={clsx('text-24-medium', 'font-golos')}
        >
          Добавить аккаунт 1C
        </CustomTypography>
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Логин
        </CustomTypography>
        <TextField
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={clsx('custom', styles.textfield)}
          fullWidth
          variant="standard"
          error={!!usernameError}
          helperText={usernameError}
        />
        <CustomTypography style={{ marginBottom: 8 }} className={clsx('text-14')}>
          Пароль
        </CustomTypography>
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={clsx('custom', styles.textfield)}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="standard"
          error={!!bindError}
          helperText={bindError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  placement="bottom-end"
                  arrow
                  title={showPassword ? 'Скрыть' : 'Показать'}
                  classes={{
                    tooltip: styles['input-tooltip'],
                    arrow: styles['input-tooltip-arrow'],
                  }}
                >
                  <>
                    <IconButton
                      className={styles['input-adornment-button']}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                    </IconButton>
                  </>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={bind1CAccountLoading}
            onClick={handleAdd1CAccount}
            variant="custom"
            style={{ marginLeft: 24 }}
          >
            {bind1CAccountLoading && <CircularProgress size={24} style={{ color: 'white' }} />}
            {!bind1CAccountLoading && (bindError ? 'Перепривязать' : 'Добавить')}
          </Button>
        </div>
      </div>
    </div>
  );
};
