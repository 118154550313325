import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TIdmParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import Switch from '@mui/material/Switch';
import { ProviderColor, TProviderColors } from './ProviderColor';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalWithAction } from './modal/ModalWithAction';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';

const idmParams = ['url', 'mapping'];

export type EditIdmProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  groups: string;
  provider_colors: TProviderColors;
  auto_registration: boolean;
  mapping: string;
  auth_without_email: boolean;
  password_required: boolean;
  is_public: boolean;
  provider_title: string;
  show_provider_avatar: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  groups: yup
    .string()
    .max(255, 'Группы поиска не могут превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Группы поиска не могут состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера IDM не может превышать 255 символов')
    .url('Введите корректный адрес'),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  disable_password_reset: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  provider_colors: yup.object({
    button_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
    font_color: yup
      .string()
      .required('Обязательное поле')
      .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
  }),
});

export const EditIdmProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditIdmProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateIdmResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const showAvatar = useWatch({ control, name: 'show_provider_avatar' });

  useEffect(() => {
    if (updateIdmResult.isSuccess) close();
  }, [updateIdmResult]);

  useEffect(() => {
    if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (provider?: Partial<TMiscProvider & { provider_id: string }>) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              provider_id: string;
              client_id: string;
            }
          >
        | undefined;

      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, params, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.client_id;

      if (type !== MiscProviderType.IDM) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (params) {
          (Object.keys(params) as Array<keyof TIdmParams>).forEach((field) => {
            setValue(field, (params as TIdmParams)?.[field] || '', { shouldDirty: !provider });
          });
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<EditIdmProviderInputs, keyof TIdmParams | 'avatar'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public' ||
              field === 'disable_password_reset'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }

            if (field === 'provider_colors') {
              return setValue(
                field,
                { ...(restInputs?.[field] as TProviderColors) },
                { shouldDirty: !provider },
              );
            }

            setValue(field, restInputs?.[field] || '', { shouldDirty: !provider });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditIdmProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (
          acc: Partial<
            Omit<TMiscProvider, 'avatar' | 'params'> & {
              avatar?: File | null;
              provider_id: string;
            } & TIdmParams
          >,
          field,
        ) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }
          if (
            field === 'auth_without_email' ||
            field === 'auto_registration' ||
            field === 'password_required' ||
            field === 'is_public' ||
            field === 'show_provider_avatar' ||
            field === 'disable_password_reset'
          ) {
            acc[field] = data[field];
            return acc;
          }

          if (field === 'provider_colors') {
            acc[field] = data[field];
            return acc;
          }

          if (idmParams.includes(field)) {
            (idmParams as Array<keyof TIdmParams>).forEach((param) => {
              acc[param] = data[param];
            });
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType.IDM,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать провайдер IDM
            </CustomTypography>
            <IconWithTooltip
              iconType="pasteSettings"
              action={() => {
                setFields();
              }}
            />
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Адрес сервера IDM
            </CustomTypography>
            <TextField
              {...register('url', {
                required: true,
                onBlur: (event: FocusEvent<HTMLInputElement>) => {
                  setValue('url', event.target.value.trim());
                },
                onChange: () => {
                  if (errors.url) clearErrors('url');
                },
              })}
              className="custom"
              error={!!errors.url}
              helperText={errors.url ? errors.url.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Адрес сервера IDM
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Группы поиска IDM
            </CustomTypography>
            <TextField
              {...register('groups', {
                required: true,
                onBlur: (event: FocusEvent<HTMLInputElement>) => {
                  setValue('groups', event.target.value.trim());
                },
                onChange: () => {
                  if (errors.groups) clearErrors('url');
                },
              })}
              className="custom"
              error={!!errors.groups}
              helperText={errors.groups ? errors.groups.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Название групп IDM.Trusted.Net через &quot;,&quot;
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Сопоставление атрибутов IDM
            </CustomTypography>
            <TextField
              {...register('mapping', {
                required: true,
                onChange: () => {
                  if (errors.mapping) clearErrors('mapping');
                },
              })}
              className="custom"
              error={!!errors.mapping}
              helperText={errors.mapping ? errors.mapping.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Сопоставление атрибутов профиля пользователя Trusted.ID с атрибутами пользователя
              внешней системы в формате: given_name:givenName, family_name:surname, email:email,
              picture:photo
            </CustomTypography>
            <ProviderFooter type={MiscProviderType.IDM} clientId={clientId} />
            <div className={styles['switch-wrapper']}>
              <CustomTypography className={clsx('text-14')}>
                Показывать логотип IDM в виджете
              </CustomTypography>
              <Switch
                checked={showAvatar}
                {...register('show_provider_avatar', {
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    setValue('show_provider_avatar', event.target.checked, { shouldDirty: true });
                  },
                })}
              />
            </div>
            <ProviderColor avatarSrc={avatarSrc} providerName={providerToEdit?.name} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={updateIdmResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для IDM-провайдера."
      />
    </Drawer>
  );
};
