import { Button, Drawer, IconButton, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import clsx from 'clsx';
import styles from './EditEmailTemplates.module.css';
import { CustomTypography } from './custom/CustomTypography';
import { IEmailTemplate } from './EmailTemplate';
import { ViewEmailTemplate } from './ViewEmailTemplate';
import { useEditSettingsMutation } from 'src/redux/services/settings';

type TEditEmailTemplate = {
  isOpen: boolean;
  close: () => void;
  template?: IEmailTemplate;
};

export const EditEmailTemplate: FC<TEditEmailTemplate> = ({ isOpen, close, template }) => {
  const [content, setContent] = useState(template?.content || '');
  const [editSettings] = useEditSettingsMutation();

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSave = async () => {
    if (template) {
      try {
        await editSettings({
          email_templates: [{ action: template.action, content, title: template.title }],
        }).unwrap();
        close();
      } catch (error) {
        console.error('Failed to save the template:', error);
      }
    }
  };

  useEffect(() => {
    setContent(template?.content || '');
  }, [template]);

  return (
    <>
      <Drawer
        onClose={() => close()}
        open={isOpen}
        anchor="right"
        variant="temporary"
        classes={{ paper: styles['drawer-paper'] }}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Редактировать шаблон письма <br />
              {template ? `'${template.title.toLowerCase()}'` : ''}
            </CustomTypography>
            <IconButton onClick={close} className={styles['close-button']}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <CustomTypography className={clsx('text-15', styles['input-description'])} color="grey">
              Измените шаблон письма для события {template ? template.action : ''}
            </CustomTypography>
          </div>
          <div>
            <ViewEmailTemplate content={content} />
          </div>
          <div>
            <TextField
              className="text-12"
              multiline
              rows={10}
              variant="outlined"
              fullWidth
              value={content}
              onChange={handleContentChange}
            />
          </div>
          <div className={styles['submit-buttons']}>
            <Button
              onClick={() => {
                close();
              }}
              variant="custom"
              color="secondary"
            >
              Отмена
            </Button>
            <Button style={{ marginLeft: 24 }} type="submit" variant="custom" onClick={handleSave}>
              Сохранить
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};
