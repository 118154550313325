import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  OauthProviderType,
  useCreateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty, isOwner } from '../helpers';
import { BACKEND_URL } from '../constants';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { RootState } from '../redux/rootReducer';
import { useSelector } from 'react-redux';
import { TTemplate } from './ChooseProvider';
import { CreateProviderInputs, CreateProviderParamsInputs } from './CreateProvider';
import { ModalWithAction } from './modal/ModalWithAction';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

type TCreateProvider = {
  isOpen: boolean;
  close: (createChooseProvider?: boolean) => void;
  providerTemplate: TTemplate;
};

export const CreateProviderByTemplate: FC<TCreateProvider> = ({
  isOpen,
  close,
  providerTemplate,
}) => {
  const methods = useForm<CreateProviderInputs & { path_to_avatar: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      is_public: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [providerType, setProviderType] = useState<OauthProviderType | MiscProviderType | null>(
    null,
  );
  const [providerTypeFromClipboard, setProviderTypeFromClipboard] =
    useState<OauthProviderType | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue('path_to_avatar', providerTemplate.pathToAvatar, { shouldDirty: false });
    setProviderType(providerTemplate.type);
    setValue('name', providerTemplate.name, { shouldDirty: false });
  }, [providerTemplate]);

  useEffect(() => {
    if (providerType !== null && providerTypeFromClipboard !== null) {
      if (providerType === providerTypeFromClipboard) {
        setFields();
      }
    }
  }, [providerType]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => {
    setProviderType(providerTemplate.type);
    setClipboardModalOpen(false);
  };
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) {
      close();
      setProviderType(null);
      setProviderTypeFromClipboard(null);
    } else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateProviderInputs, 'avatar'> & {
          id: string;
          avatar: string;
          type: OauthProviderType;
          params: CreateProviderParamsInputs;
        }
      > = JSON.parse(text);
      const { type, avatar, params, ...restInputs } = provider || {};
      delete restInputs.id;

      if (type !== providerType) {
        setClipboardModalOpen(true);

        if (typeof type === 'string' && type in OauthProviderType) {
          setProviderTypeFromClipboard(type);
        }
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }

        if (restInputs) {
          (Object.keys(restInputs) as Array<keyof Omit<CreateProviderInputs, 'avatar'>>).forEach(
            (field) => {
              if (
                field === 'auto_registration' ||
                field === 'auth_without_email' ||
                field === 'password_required' ||
                field === 'is_public'
              ) {
                return setValue(field, restInputs?.[field] === true, { shouldDirty: true });
              }

              if (field === 'name' || field === 'description') {
                setValue(field, restInputs?.[field] || '', { shouldDirty: true });
              }
            },
          );
        }

        if (params) {
          (Object.keys(params) as Array<keyof CreateProviderParamsInputs>).forEach((field) => {
            if (field === 'external_client_id' || field === 'external_client_secret') {
              setValue(field, params?.[field] || '', { shouldDirty: true });
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        ...rest,
        type: providerType ? providerType : providerTemplate.type,
        avatar: avatar ? avatar : null,
        is_active: true,
      },
      client_id: clientId,
    });
    setProviderType(null);
    setProviderTypeFromClipboard(null);
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <CustomTypography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа
            </CustomTypography>
            <IconWithTooltip
              iconType="pasteSettings"
              action={() => {
                setFields();
              }}
            />
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={providerTemplate.scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              setAvatarValue={setAvatarValue}
              pathToAvatar={providerTemplate.pathToAvatar}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Идентификатор ресурса (client_id)
            </CustomTypography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Уникальный идентификатор подключаемого ресурса
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Секретный ключ (client_secret)
            </CustomTypography>
            <PasswordTextfield
              {...register('external_client_secret', {
                required: true,
                onChange: () => {
                  if (errors.external_client_secret) clearErrors('external_client_secret');
                },
              })}
              className="custom"
              error={!!errors.external_client_secret}
              helperText={
                errors.external_client_secret ? errors.external_client_secret.message : ''
              }
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Секретный ключ ресурса
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Redirect URI
            </CustomTypography>
            <TextField
              value={BACKEND_URL + '/api/interaction/code'}
              disabled
              className="custom"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconWithTooltip
                      iconType="copy"
                      action={() => {
                        navigator.clipboard.writeText(BACKEND_URL + '/api/interaction/code');
                      }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  disabled: styles['input-wrapper-disabled'],
                },
              }}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Cсылка должна быть указана в настройках внешних способов входа для корректной
              аутентификации пользователя
            </CustomTypography>
            <ProviderFooter type={providerType as OauthProviderType} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading}
              variant="custom"
              type="submit"
            >
              Создать
            </Button>
          </div>
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
          setProviderType(null);
          setProviderTypeFromClipboard(null);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для выбранного шаблона провайдера."
      />
    </Drawer>
  );
};
